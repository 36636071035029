import Routing from './routing';

class TagsEditor {
    private readonly buttonNewTag: JQuery<HTMLElement>;
    private readonly element: JQuery<HTMLElement>;
    private modalNewTag: JQuery<HTMLElement>;
    private tagsChosen: JQuery<HTMLElement>;
    private displayValidateErr: JQuery<HTMLElement>;
    private closeModalBtn: JQuery<HTMLElement>;

    public constructor(elem: JQuery<HTMLElement>) {
        this.element            = elem;
        this.buttonNewTag       = this.element.find('.create-new-tag');
        this.modalNewTag        = this.element.siblings(this.buttonNewTag.data('target'));
        this.tagsChosen         = this.element.find('select[data-toggle="chosen"]');
        this.displayValidateErr = this.modalNewTag.find('[role="alert"]');
        this.closeModalBtn      = this.modalNewTag.find('.close-modal');

        this.init();
    }

    private init(): void {

        this.modalNewTag.on('shown.bs.modal', () => {
            this.modalNewTag.find('input[type="text"]')[0].focus();
        });
        this.modalNewTag.on('hide.bs.modal', () => {
            this.hiddenValidateError();
        });
        this.modalNewTag.find('.save-tag').on('click', () => {
            jQuery.ajax({
                url:      Routing.generate('tag_create'),
                method:   'POST',
                data:     {
                    'language': this.element.data('defaultLanguage'),
                    'tag':      this.modalNewTag.find('input[type="text"]').val()
                },
                complete: (jqXHR) => {

                    if (jqXHR.responseJSON.hasOwnProperty('languages')) {
                        this.addTagToList(jqXHR.responseJSON);
                        this.modalNewTag.modal('hide');
                        this.hiddenValidateError();
                        this.modalNewTag.find('input[type="text"]').val('');
                    } else {
                        this.showValidateErr(jqXHR.responseJSON?.message);
                    }
                }
            });
        });

        this.closeModalBtn.on('click', () => {
            this.modalNewTag.modal('hide');
        });
    }

    private addTagToList(response: any): void {
        for (const option of this.tagsChosen.find('option')) {
            if (option.value === response.id) {
                return;
            }
        }
        this.tagsChosen.append('<option value="' + response.id + '">' + response.languages[0].text + '</option>');
        this.tagsChosen.trigger("change");
    }

    private showValidateErr(message: string): void {
        this.displayValidateErr.removeClass('d-none').find('.form-error-message').html(message);
    }

    private hiddenValidateError(): void {
        if (!this.displayValidateErr.hasClass('d-none')) {
            this.displayValidateErr.addClass('d-none');
        }
    }
}

export default TagsEditor;

declare global {
    interface JQuery {
        tagsEditor()
    }
}

(function ($) {
    $.fn.tagsEditor = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let tagsEditor = jQuery(elem).data('tagsEditor');
            if (typeof tagsEditor === 'undefined') {
                tagsEditor = new TagsEditor(jQuery(elem));
                jQuery(elem).data('tagsEditor', tagsEditor);
            }

            return tagsEditor;
        });
    }
})(jQuery);
