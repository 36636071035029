import {OptGroupData, OptionData, SearchOptions} from "select2";
import select2Matcher from "./select2-matcher";

class NotificationForm
{
    private readonly itemsSelector: JQuery<HTMLElement>;

    /**
     * 
     * @param elem
     */
    constructor(elem: JQuery<HTMLElement>) {
        this.itemsSelector = elem.find('[id$="_item"]');

        this.initItemsSelector();
    }

    /**
     *
     */
    public initItemsSelector() : void
    {
        this.itemsSelector.select2({
            allowClear:  true,
            matcher: select2Matcher
        });
    }
}

export default NotificationForm;

declare global {
    interface JQuery {
        notificationForm();
    }
}

(function ($) {

    $.fn.notificationForm = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let notificationForm = jQuery(elem).data('notificationForm');
            if (typeof notificationForm === 'undefined') {
                notificationForm = new NotificationForm(jQuery(elem));
                jQuery(elem).data('notificationForm', notificationForm);
            }
            return notificationForm;
        });
    }

})(jQuery);