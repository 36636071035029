class SubtitleGenerator {

    private limitChar: number;
    private linesPerLength: Array<string>;
    private readonly limitFirstPoint: number;
    private readonly limitEndPoint: number;
    private subtitlesProcessed: Array<{ duration: number, subtitle: string }>;
    private durationMsPerCharacter: number;
    private RegexListSubStr: any;
    private regexNumberRomans: any;
    private rangeSplitLine: { min: number, max: number };
    private numberSplitMiddleLine: number;
    private valueTimePerNumberRoman: number;

    /**
     * @constructor
     */
    constructor() {

        this.limitChar          = 90;
        this.linesPerLength     = [];
        this.subtitlesProcessed = [];

        /**
         * Percentage first point in length line
         */
        this.limitFirstPoint = 20;

        /**
         * if a period appears at the end of the sentence
         */
        this.limitEndPoint = 80;

        this.RegexListSubStr = /[0-9,.;:?!h]/g;

        this.regexNumberRomans       = /(?=\b[MCDXLVI]{1,6}\b)M{0,4}(?:CM|CD|D?C{0,3})(?:XC|XL|L?X{0,3})(?:IX|IV|V?I{0,3})/g;
        this.valueTimePerNumberRoman = 5;

        /**
         * Ranges per percentage by line
         */
        this.rangeSplitLine = {min: 40, max: 60};

        /**
         * Middle length line
         */
        this.numberSplitMiddleLine = 40;
    }

    /**
     * @param locution
     * @return Array<string>
     */
    public createSubtitles(locution: string): void
    {

        this.subtitlesProcessed = [];

        locution = this.validateLocutionText(locution);

        this.setSubtitlesLinesByLength(locution);
        this.iterateForRestWordToBackOrNextLine();
        this.checkBreakLine();
        this.setStructuredObjectResult();
    }

    /**
     * @param amount
     */
    public setDurationMsPerCharacter(amount: number): void
    {
        this.durationMsPerCharacter = amount;
    }

    /**
     * @return void
     */
    private setStructuredObjectResult(): void
    {
        for (let i: number = 0; i < this.linesPerLength.length; i++) {

            let secondsDuration = this.calculateTimeDuration(this.linesPerLength[i]);

            let object: { duration: number, subtitle: string } = {
                duration: secondsDuration,
                subtitle: this.linesPerLength[i]
            };

            this.subtitlesProcessed.push(object);
        }
        this.linesPerLength = [];
    }

    /**
     * @param line
     * @return number
     */
    private calculateTimeDuration(line: string): number
    {
        let duration: number       = 0.00;
        let matches: Array<string> = line.match(this.RegexListSubStr);

        if (matches && typeof matches == 'object') {
            duration += this.calculateTimeDurationByNumberWord(matches);
        }

        duration += this.calculateNumberRomans(line);

        duration += line.replace(this.RegexListSubStr, '').length * this.durationMsPerCharacter;

        return Math.ceil(duration / 1000);
    }

    /**
     * @param line
     * @return number|string
     */
    private calculateNumberRomans(line: string): number
    {
        let duration: number       = 0.00;
        let matches: Array<string> = line.match(this.regexNumberRomans);

        if (!matches) {
            return duration;
        }
        for (let i: number; i < matches.length; i++) {
            duration += matches[i].length * this.valueTimePerNumberRoman;
        }
        return duration;
    }

    /**
     * @param matches
     */
    private calculateTimeDurationByNumberWord(matches: Array<string>): number
    {

        let duration: number = 0.00;

        for (let i: number = 0; i < matches.length; i++) {
            duration += this.durationMsPerCharacter * this.getNumberMultipleByCharacter(matches[i]);
        }
        return duration;
    }

    /**
     * @param char
     * @return number
     */
    private getNumberMultipleByCharacter(char: string): number
    {

        let multiple: number;

        switch (char) {
            case 'h':
                return multiple = 0;
            case ':':
            case '.':
                return multiple = 12;
            case ';':
            case '!':
            case '?':
            case ',':
                return multiple = 12;
            default:
                return multiple = 10;
        }
    }

    /**
     *
     */
    public getSubtitles(): Array<{ duration: number, subtitle: string }>
    {
        return this.subtitlesProcessed;
    }

    /**
     * @param locution
     */
    private validateLocutionText(locution: string) : string
    {
        return locution.replace(/[\r\n]/g, "");
    }

    /**
     * @param locution
     * @return void
     */
    private setSubtitlesLinesByLength(locution: string) : void
    {
        let text: string, words: Array<string>;

        if (locution.length) {

            words = locution.split(' ');
            text  = '';

            for (let i: number = 0; i < words.length; i++) {

                if (text.length < this.limitChar) {
                    text += words[i];
                    text += ' ';
                }
                else {
                    this.linesPerLength.push(text.trim());
                    text = words[i];
                    text += ' ';
                }
            }
            if (text.length) {
                this.linesPerLength.push(text.trim());
            }
        }
    }

    /**
     *
     * @param line
     * @param char
     */
    private getIndexLocationSubstring(line: string, char: string): number | boolean
    {
        let min: number,
            max: number,
            index: number;

        index = line.lastIndexOf(char);

        min = Math.ceil(line.length * this.rangeSplitLine.min / 100);
        max = Math.ceil(line.length * this.rangeSplitLine.max / 100);

        if (index <= max && index >= min) {
            return index;
        }
        return false;
    }

    /**
     * @return void
     */
    private checkBreakLine(): void
    {
        let index;

        for (let i: number = 0; i < this.linesPerLength.length; i++) {

            index = this.getIndexLocationSubstring(this.linesPerLength[i], '.');
            if (index) {
                this.linesPerLength[i] = this.addBreakLineByIndex(this.linesPerLength[i], index);
                continue;
            }
            index = this.getIndexLocationSubstring(this.linesPerLength[i], ',');
            if (index) {
                this.linesPerLength[i] = this.addBreakLineByIndex(this.linesPerLength[i], index);
                continue;
            }
            index = this.getIndexLocationSubstring(this.linesPerLength[i], '?');
            if (index) {
                this.linesPerLength[i] = this.addBreakLineByIndex(this.linesPerLength[i], index);
                continue;
            }
            if (this.linesPerLength[i].length > this.numberSplitMiddleLine) {
                index                  = Math.ceil(this.linesPerLength[i].length / 1.9);
                this.linesPerLength[i] = this.addBreakLineByIndex(this.linesPerLength[i], index);
            }
        }
    }

    /**
     * @param line
     * @param index
     * @return string
     */
    private addBreakLineByIndex(line: string, index: number): string
    {
        let subtitle: string = '';

        while (line.substr(index, 1) != ' ') {
            index++;
        }
        subtitle += line.substr(0, index);
        subtitle += "\n";
        subtitle += line.substr(index, line.length).trim();

        return subtitle;
    }

    /**
     * @return void
     */
    private iterateForRestWordToBackOrNextLine(): void
    {
        for (let i: number = 0; i < this.linesPerLength.length; i++) {
            this.evalSplitByCharAddToNextOrBackLine(i, '.');
        }
    }

    /**
     *
     * @param index
     * @param char
     * @return void
     */
    private evalSplitByCharAddToNextOrBackLine(index: number, char: string): void
    {
        let words: Array<string>,
            lenStr: number,
            min: number,
            max: number;

        lenStr = this.linesPerLength[index].length;
        min   = lenStr * this.limitFirstPoint / 100;

        if (this.linesPerLength[index].indexOf(char) < min && this.linesPerLength[index].indexOf(char) > -1) {

            words = this.linesPerLength[index].split(char);

            if (index > 0) {
                this.linesPerLength[index - 1] += ' ' + words[0] + char;
                this.linesPerLength[index] = this.linesPerLength[index].substr(this.linesPerLength[index].indexOf(char) + 1, lenStr).trim();
            }
        }

        max = lenStr * this.limitEndPoint / 100;

        if (this.linesPerLength[index].lastIndexOf(char) > max) {

            words = this.linesPerLength[index].split(char);

            if (index < this.linesPerLength.length - 1) {
                this.linesPerLength[index + 1] = words[words.length - 1].trim() + ' ' + this.linesPerLength[index + 1];
                this.linesPerLength[index] = this.linesPerLength[index].replace(words[words.length - 1], '');
            }
        }
    }
}

export default SubtitleGenerator;
