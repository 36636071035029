import Model3dRenderer from "./model3d-renderer";

class AdvancedUploader {

    private readonly bntDownload;
    private readonly btnDelete;
    private readonly btnPreview;
    private readonly elem: JQuery<HTMLElement>;
    private readonly maxUploadFileSize;
    private readonly previewCollapse;
    public readonly changeFieldEvent = 'onChangeInputFileValue';
    private readonly typeFile: string;
    private model3dRenderer: Model3dRenderer;

    /**
     *
     * @param elemParam
     * @param options
     * @param arg
     */
    public constructor(elemParam: JQuery<HTMLElement>, options?: string | object, arg?) {

        this.elem              = elemParam;
        this.btnPreview        = this.elem.find('.btn.preview');
        this.bntDownload       = this.elem.find('.btn.download');
        this.btnDelete         = this.elem.find('.btn.delete');
        this.previewCollapse   = jQuery(this.btnPreview.data('target'));
        this.maxUploadFileSize = parseInt(this.elem.find('input[type="hidden"][data-toggle="upload-max-file-size"]').val().toString());

        if (options && typeof (options) == 'string') {
            if (options === 'beginProgress') {
                this.beginProgress();
            }
            else if (options === 'setValue') {
                this.setValue(arg);
            }
            else if (options === 'setProgress') {
                this.setProgress(arg);
            }
            else if (options === 'endProgress') {
                this.endProgress();
            }
            else if (options === 'hidePreview') {
                this.hidePreview();
            }
            return;
        }

        let fileField = this.elem.find('input[type="file"]');
        fileField.hide();

        jQuery('body').append(fileField);

        this.setUploadTriggerEvent(fileField);
        this.setPreviewEventBtn();
        this.setDownloadEvent();
        this.setPreviewCollapseEvent();
        this.setDeleteEventBtn(fileField);
        this.setUpload(fileField);
        this.typeFile = this.getFileType();

        if (this.typeFile === 'image') {
            this.collapsePreview();
        }
        if (this.typeFile === 'model3d') {
            this.model3dRenderer = new Model3dRenderer(this.previewCollapse.find('.model3d-preview'));
        }
    }

    /**
     * Upload trigger click event
     */
    private setUploadTriggerEvent(fileField: JQuery<HTMLElement>): void {
        this.elem.find('.btn.upload, input[type="text"]').on('click', (e: JQuery.TriggeredEvent) => {
            fileField.trigger('click');
            e.target.blur();
        });
    }

    /**
     * See preview event
     */
    private setPreviewEventBtn() {
        this.btnPreview.on('click', () => {
            this.collapsePreview();
        });
    }

    /**
     *
     */
    private collapsePreview(): void {
        if (!this.btnPreview.hasClass('disabled')) {
            this.previewCollapse.collapse('toggle');
        }
        this.btnPreview.trigger('blur');
    }

    /**
     * Download event
     */
    private setDownloadEvent(): void {
        this.bntDownload.on('click', (e: JQuery.TriggeredEvent) => {
            if (!this.bntDownload.hasClass('disabled')) {
                let file: string = this.getSourceUrl();
                if (file) {
                    AdvancedUploader.download(file);
                }
            }
            e.target.blur();
        });
    }

    /**
     * @private
     */
    private getSourceUrl(): string|null {
        return <string> this.elem.find('input[type="text"]').val()
    }

    /**
     * @private
     */
    private getFileType(): string {
        return <string> this.elem.find("[data-toggle='file-type']").val();
    }

    /**
     *
     */
    private setPreviewCollapseEvent(): void {
        this.previewCollapse
            .on('shown.bs.collapse', () => {
                const parentModal = this.elem.parents('.modal');
                if (parentModal) {
                    parentModal.on('hidden.bs.modal', () => {
                        this.previewCollapse.collapse('hide');
                    });
                }
                if (this.typeFile === 'model3d' && this.getSourceUrl()) {
                    this.model3dRenderer.previewObject(this.getSourceUrl());
                }
            })
            .on('hidden.bs.collapse', () => {
                if (this.typeFile === 'audio' || this.typeFile === 'video') {
                    const media = this.previewCollapse.find('audio, video')[0];
                    media.pause();
                    media.currentTime = 0;
                }
                if (this.typeFile === 'model3d') {
                    this.model3dRenderer.destroy();
                }
            });
    }

    /**
     *
     * @param fileField
     */
    private setUpload(fileField: JQuery<HTMLElement>): void {

        fileField.fileupload({
            url:         this.elem.find('input[type="hidden"][data-toggle="upload-path"]').val().toString(),
            dataType:    'json',
            done: (e: JQueryEventObject, data: JQueryFileUploadDone) => {
                this.endProgress();
                this.setValue(data.result.files[0]);
                this.elem.trigger('fileUploaded', data.result.files[0]);

            },
            add: (e: JQueryEventObject, data: JqueryFileUploadAddObject) => {
                if (data.files[0].size > this.maxUploadFileSize) {
                    this.elem.find('.error-file-size').removeClass('d-none').addClass('d-block');
                }
                else {
                    this.beginProgress();
                    data.submit();
                }
            },
            progressall: (e: JQueryEventObject, data: JQueryFileUploadProgressAllObject) => {
                const progress = Math.round(data.loaded / data.total * 100);
                this.setProgress(progress);
            }
        });
    }

    /**
     * Delete handle event
     */
    private setDeleteEventBtn(fileField): void {
        this.btnDelete.on('click', (e: JQuery.TriggeredEvent) => {
            this.elem.find('input[type="text"]').val('').trigger(this.changeFieldEvent);
            this.elem.find('.btn.preview').addClass('disabled');
            this.elem.find('.btn.download').addClass('disabled');
            this.elem.find('.modal[role="dialog"]').find('img, video, audio').attr('src', '');

            this.previewCollapse.collapse('toggle');
            this.previewCollapse.empty();

            fileField.val('');
            this.setUpload(fileField);

            e.target.blur();
        });
    }

    /**
     * @param uri
     */
    private static download(uri: string): void {
        const element = document.createElement('a');
        element.setAttribute('href', '/' + uri);

        const pieces: string[] = uri.split('/');
        const filename = pieces[pieces.length-1];

        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

    /**
     *
     */
    private beginProgress(): void {
        this.elem.find('.error-file-size').removeClass('d-block').addClass('d-none');
        this.elem.find('.progress').parent().removeClass('d-none').addClass('d-flex');
        this.elem.find('input[type="text"]').addClass('d-none');
    }

    /**
     *
     */
    private endProgress(): void {
        this.elem.find('.progress').parent().removeClass('d-flex').addClass('d-none');
        this.elem.find('input[type="text"]').removeClass('d-none');
    }

    /**
     *
     */
    private hidePreview(): void {
        this.previewCollapse.collapse('hide')
    }

    /**
     *
     * @param progress
     */
    private setProgress(progress): void {
        this.elem.find('.progress .progress-bar').css('width', progress + '%');
    }

    /**
     *
     * Add values to input text when success upload
     * @param value
     */
    private setValue(value): void {

        this.elem.find('input[type="text"]').val(value).trigger(this.changeFieldEvent);
        this.elem.find('.btn.preview').removeClass('disabled');
        this.elem.find('.btn.download').removeClass('disabled');

        let img        = this.elem.siblings('.collapse').find('img');
        let multimedia = this.elem.siblings('.collapse').find('video, audio');

        if (img.length) {
            img.attr('src', '/' + value);
        }
        else if (multimedia.length) {
            multimedia.empty();
            multimedia.append(jQuery('<source>').attr('src', '/' + value));
        }
    }
}

declare global {
    interface JQuery {
        advancedUploader(options?: string | object, arg?);
    }
}


(function ($) {
    $.fn.advancedUploader = function (options?: string | object, arg?) {
        return this.each((index: number, elem: HTMLElement) => {
            new AdvancedUploader(jQuery(elem), options, arg);
        });
    }
})(jQuery);
