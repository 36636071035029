require('bootstrap-colorpicker');
require('bootstrap-colorpicker/src/sass/colorpicker.scss');

class ColorPicker {
    constructor(elem: HTMLElement) {
        const colorPicker = jQuery(elem).colorpicker({
            format: 'hex'
        });
        colorPicker.find('input').on('click', (e: JQuery.TriggeredEvent) => {
            e.preventDefault();
            jQuery(e.target).parent().find('.colorpicker-input-addon').trigger('focus');
        });
    }
}

export default ColorPicker;
