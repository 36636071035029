import ThreeJSHelper, {AnimationStatus} from "./threejs-helper";
import RecreaAPI from "./recrea-api";
import * as THREE from "three";
import ColorPicker from "./colorpicker";
import Translator from "./transator";

export default class Scene360Manager {
    private readonly backgroundColorPrototype: string;
    private readonly backgroundList: JQuery<HTMLElement>;
    private backgroundType: string;
    private readonly backgroundUploadPrototype: string;
    private elem: JQuery<HTMLElement>;
    private preview: JQuery<HTMLElement>;
    private readonly recreaAPI: RecreaAPI = new RecreaAPI();
    private threeJSHelper: ThreeJSHelper;

    constructor(elem: JQuery<HTMLElement>) {
        const modelList: JQuery<HTMLElement>            = elem.find('.model-list');
        const btnAddModel: JQuery<HTMLElement>          = elem.find('.btn.add-model');
        const selectBackgroundType: JQuery<HTMLElement> = elem.find('select[name="scene360[backgroundType]"]');
        let index: number                               = modelList.children().length;

        // Get prototype string
        const prototype: string = modelList.attr('data-prototype');
        modelList.attr('data-prototype', null);

        btnAddModel.on('click', (e: JQuery.Event) => {
            e.preventDefault();

            const newModelHTML = jQuery.trim(prototype.trim().replace(/__name__/g, index.toString())); // Clean prototype
            const newModel     = <HTMLElement[]>jQuery.parseHTML(newModelHTML); // Generate new element
            modelList.append(newModel);
            this.initModelForm(jQuery(newModel));
            index++; // Update models count
        });

        this.elem    = elem;
        this.preview = this.elem.find('.preview');

        if (this.preview.length > 0) {
            // Get backgrounds list and prototypes for new fields
            this.backgroundList            = this.elem.find('.scene360-background-list');
            this.backgroundColorPrototype  = this.backgroundList.attr('data-prototype_color');
            this.backgroundUploadPrototype = this.backgroundList.attr('data-prototype_upload');
            this.backgroundList.attr('data-prototype_color', null);
            this.backgroundList.attr('data-prototype_upload', null);

            this.threeJSHelper = new ThreeJSHelper(this.elem.find('.preview')[0], undefined, true);
            this.threeJSHelper.init();
            this.threeJSHelper.setAnimated(AnimationStatus.disabled);
            this.threeJSHelper.showAxesHelper();
            this.threeJSHelper.setSceneSize(55);

            this.initModelManager(modelList.children());

            this.initBackgroundType(selectBackgroundType.find('option:selected').html());

            selectBackgroundType.on('change', () => {
                this.initBackgroundType(selectBackgroundType.find('option:selected').html());
            });
        }
    }

    private static moveModel(model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene, vector: THREE.Vector3): void {
        if (model instanceof THREE.Scene) {
            model.position.set(
                vector.x,
                vector.y,
                vector.z
            );
        }
        else if (model instanceof THREE.BufferGeometry || model instanceof THREE.Geometry) {
            model.translate(vector.x, vector.y, vector.z);
        }
    }

    private static rotateModel(model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene, rotX: number, rotY: number, rotZ: number): void {
        if (model instanceof THREE.Scene) {
            model.setRotationFromEuler(
                new THREE.Euler(
                    (rotX * (Math.PI / 180)),
                    (rotY * (Math.PI / 180)),
                    (rotZ * (Math.PI / 180))
                )
            );
        }
        else if (model instanceof THREE.BufferGeometry || model instanceof THREE.Geometry) {
            model.rotateX((rotX * (Math.PI / 180)));
            model.rotateY((rotY * (Math.PI / 180)));
            model.rotateZ((rotZ * (Math.PI / 180)));
        }
    }

    private static scaleModel(model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene, scale: number): void {
        if (model instanceof THREE.Scene) {
            model.scale.set(scale, scale, scale);
        }
        else if (model instanceof THREE.BufferGeometry || model instanceof THREE.Geometry) {
            model.scale(scale, scale, scale);
        }
    }

    private adjustBackgroundForm(backgroundType: string): void {
        let numFields = 1;
        if (backgroundType === 'CUBIC') {
            numFields = 6;
        }

        let fieldType = 'image';
        if (backgroundType === 'COLOR') {
            fieldType = 'COLOR';
        }

        if (backgroundType !== this.backgroundType
            && typeof this.backgroundType !== 'undefined'
            && (backgroundType === 'COLOR' || this.backgroundType === 'COLOR')) {
            this.backgroundList.empty();
        }

        while (this.backgroundList.children().length > numFields) {
            this.backgroundList.children().last().remove();
        }

        while (this.backgroundList.children().length < numFields) {
            const newField = jQuery(
                (fieldType === 'COLOR' ? this.backgroundColorPrototype : this.backgroundUploadPrototype)
                    .trim()
                    .replace(/__name__/g, (this.backgroundList.children().length + 1).toString())
            );
            this.backgroundList.append(newField);

            if (backgroundType === 'COLOR') {
                new ColorPicker(newField.find('[data-toggle="color-picker"]')[0]);
            }
            else {
                newField.find('.advanced-file-manager').advancedUploader();
            }
        }
        if (backgroundType === 'CUBIC') {

            let placeholders = [
                Translator.trans('generic.referenceRight', {}, 'messages'),
                Translator.trans('generic.referenceLeft', {}, 'messages'),
                Translator.trans('generic.referenceUp', {}, 'messages'),
                Translator.trans('generic.referenceDown', {}, 'messages'),
                Translator.trans('generic.referenceFront', {}, 'messages'),
                Translator.trans('generic.referenceBack', {}, 'messages')
            ];
            for (var i=0;i<placeholders.length;i++) {
                this.backgroundList.find('[name="scene360[background][' + (i+1) + ']"]').attr('placeholder', placeholders[i]);
            }
        } else {
            this.backgroundList.find('input[type="text"]').attr('placeholder','');
        }

        this.backgroundType = backgroundType;
    }

    private drawBackground() {
        this.threeJSHelper.removeBackground();

        switch (this.backgroundType) {
            case 'COLOR':
                const color = (this.backgroundList.children().find('[data-toggle="color-picker"] input[type="color"]').val() || '').toString();
                this.threeJSHelper.drawColorBackground(color);
                break;
            case 'CUBIC':
                const [posX, negX, posY, negY, posZ, negZ]: JQuery<HTMLInputElement> = this.backgroundList.children().find('.advanced-file-manager input[type="text"]') as JQuery<HTMLInputElement>;
                this.threeJSHelper.drawCubicBackground(
                    '/' + posX.value,
                    '/' + negX.value,
                    '/' + posY.value,
                    '/' + negY.value,
                    '/' + posZ.value,
                    '/' + negZ.value
                );
                break;
            case 'IMAGE':
                const image: HTMLInputElement = this.backgroundList.children().find('.advanced-file-manager input[type="text"]')[0] as HTMLInputElement;
                this.threeJSHelper.drawImageBackground('/' + image.value);
                break;
            case 'PANO':
                const pano: HTMLInputElement = this.backgroundList.children().find('.advanced-file-manager input[type="text"]')[0] as HTMLInputElement;
                this.threeJSHelper.drawPanoBackground('/' + pano.value);
                break;
        }
    }

    private drawRecreaModel(projectId: string, center: THREE.Vector3): Promise<THREE.Geometry | THREE.BufferGeometry | THREE.Scene> {
        return new Promise((resolve: (value: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => void) => {
            this.recreaAPI.getModel(projectId, 5)
                .then((request: XMLHttpRequest) => {
                    this.threeJSHelper
                        .drawData(request.response, center, ThreeJSHelper.ModelType.gltf, null, false, request.getResponseHeader('content-type'))
                        .then((model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => {
                            resolve(model);
                        });
                });
        });
    }

    private initBackgroundType(backgroundType: string): void {
        this.adjustBackgroundForm(backgroundType);
        this.drawBackground();

        switch (backgroundType) {
            case 'CUBIC':
                this.threeJSHelper.lockCenterCamera();
                break;
            default:
                this.threeJSHelper.unlockCenterCamera();
                break;
        }
    }

    private initModelForm(model: JQuery<HTMLElement>): void {
        const btnDelete    = model.find('.delete-model');
        const selectRecrea = model.find('select[name^="scene360[scene360Models]"][name$="[recrea]"]');
        const fileModel    = model.find('input[name^="scene360[scene360Models]"][name$="[model]"]');
        const inputPosX    = model.find('input[name^="scene360[scene360Models]"][name$="[position][x]"]');
        const inputPosY    = model.find('input[name^="scene360[scene360Models]"][name$="[position][y]"]');
        const inputPosZ    = model.find('input[name^="scene360[scene360Models]"][name$="[position][z]"]');
        const inputRotX    = model.find('input[name^="scene360[scene360Models]"][name$="[rotation][x]"]');
        const inputRotY    = model.find('input[name^="scene360[scene360Models]"][name$="[rotation][y]"]');
        const inputRotZ    = model.find('input[name^="scene360[scene360Models]"][name$="[rotation][z]"]');
        const inputScale   = model.find('input[name^="scene360[scene360Models]"][name$="[scale]"]');

        const recreaModelId: string = (selectRecrea.val() || '').toString();
        const modelPath: string     = (fileModel.val() || '').toString();

        const positionChanged = (model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => {
            const posX: number = Number((inputPosX.val() || '').toString().replace(',', '.'));
            const posY: number = Number((inputPosY.val() || '').toString().replace(',', '.'));
            const posZ: number = Number((inputPosZ.val() || '').toString().replace(',', '.'));

            Scene360Manager.moveModel(model, new THREE.Vector3(posX, posY, posZ));
        };
        const rotationChanged = (model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => {
            const rotX: number = Number((inputRotX.val() || '').toString().replace(',', '.'));
            const rotY: number = Number((inputRotY.val() || '').toString().replace(',', '.'));
            const rotZ: number = Number((inputRotZ.val() || '').toString().replace(',', '.'));

            Scene360Manager.rotateModel(model, rotX, rotY, rotZ);
        };
        const scaleChanged    = (model: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => {
            const scale: number = Number((inputScale.val() || '').toString().replace(',', '.'));

            Scene360Manager.scaleModel(model, scale);
        };

        let model3d: THREE.BufferGeometry | THREE.Geometry | THREE.Scene;

        selectRecrea.off('change').on('change', () => {
            this.threeJSHelper.removeObject(model3d);
            this.initModelForm(model);
        });

        if (typeof recreaModelId !== 'undefined') {
            this.drawRecreaModel(recreaModelId, new THREE.Vector3(0, 0, 0))
                .then((loadedModel: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => {
                    model3d = loadedModel;

                    positionChanged(loadedModel);
                    rotationChanged(loadedModel);
                    scaleChanged(loadedModel);

                    inputPosX.off('input').on('input', () => {
                        positionChanged(loadedModel);
                    });
                    inputPosY.off('input').on('input', () => {
                        positionChanged(loadedModel);
                    });
                    inputPosZ.off('input').on('input', () => {
                        positionChanged(loadedModel);
                    });

                    inputRotX.off('input').on('input', () => {
                        rotationChanged(loadedModel);
                    });
                    inputRotY.off('input').on('input', () => {
                        rotationChanged(loadedModel);
                    });
                    inputRotZ.off('input').on('input', () => {
                        rotationChanged(loadedModel);
                    });

                    inputScale.off('input').on('input', () => {
                        scaleChanged(loadedModel);
                    });
                });
        }
        else if (typeof modelPath !== 'undefined') {
            // TODO Previsualizar modelos subidos en GLTF
        }

        btnDelete.off('click').on('click', () => {
            model.remove();
            this.threeJSHelper.removeObject(model3d);
        });
    }

    private initModelManager(modelsList: JQuery<HTMLElement>): void {
        modelsList.each((index: number, elem: HTMLElement) => {
            const model = jQuery(elem);
            this.initModelForm(model);
        });
    }
}
