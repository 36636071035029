import Translator from "./transator";
import {OptGroupData, OptionData, SearchOptions} from "select2";
import Routing from "./routing";
import Spinner from "./spinner";
import select2Matcher from "./select2-matcher";

const uuid4 = require('uuid/v4');

class ItemsTreeEditor {

    private containerNodeEditor: JQuery<HTMLElement>;
    private readonly contentNodeEditor: JQuery<HTMLElement>;
    private readonly elem: JQuery<HTMLElement>;
    private readonly modalAjax: JQuery<HTMLElement>;
    private readonly modalAjaxBody: JQuery<HTMLElement>;
    private readonly treeEditor: JQuery<HTMLElement>;
    private readonly context: string;

    /**
     * @param elem
     * @param context
     */
    constructor(elem: JQuery<HTMLElement>, context: string) {
        this.elem                = elem;
        this.contentNodeEditor   = this.elem.find('.content-node-editor');
        this.containerNodeEditor = this.elem.find('.container-node-editor');
        this.treeEditor          = this.elem.find('[data-toggle="tree-editor"]');
        this.modalAjax           = this.elem.find('.modal-ajax');
        this.modalAjaxBody       = this.modalAjax.find('.modal-body');
        this.context             = context;

        this.init();
    }

    /**
     *
     */
    private init(): void {
        this.treeEditor.data('treeEditor').setContextMenuitemsCallable((node) => {
            return this.getTreeContextMenuItems(node);
        });

        this.treeEditor
            .on('select_node.jstree', (event: JQuery.TriggeredEvent, selection) => {
                const node = selection.node;

                if (node.parent === '#') {
                    this.contentNodeEditor.addClass('d-none');
                    this.treeEditor.jstree('deselect_node', node);
                }
                else if (node.data.type === 'content') {
                    this.contentNodeEditor.removeClass('d-none');
                    this.containerNodeEditor.addClass('d-none');

                    const itemSelector     = this.contentNodeEditor.find('#' + this.context +  '_tree_item');

                    itemSelector.off('change');
                    itemSelector.val(node.data && node.data.itemId ? node.data.itemId : null).trigger('change');
                    itemSelector.on('change', (event: JQuery.ChangeEvent) => {
                        this.setTreeNodeItemId(node, event.target.value);
                    });
                    itemSelector.select2({
                        allowClear:  true,
                        placeholder: Translator.trans('chosen.placeholderSingle', {}, 'messages'),
                        matcher: select2Matcher
                    });
                    this.LanguageSelectorHandlerEvents(node);

                }
                else if (node.data.type === 'container') {
                    this.contentNodeEditor.addClass('d-none');
                    this.containerNodeEditor.removeClass('d-none');

                    this.containerNodeEditor.find('button').off('click').on('click', (e: JQuery.TriggeredEvent) => {
                        e.preventDefault();
                        e.stopPropagation();

                        this.modalContainerEdit(node);
                    })
                }
            })
            .on('deselect_node.jstree', (event: JQuery.TriggeredEvent, selection) => {
                if (selection.selected.length === 0) {
                    this.contentNodeEditor.addClass('d-none');
                }
            })
    }

    /**
     *
     * @param node
     * @constructor
     */
    private LanguageSelectorHandlerEvents(node: any): void
    {
        if (this.context != "tour") {
            return;
        }
        const languageSelector = this.contentNodeEditor.find('#' + this.context +  '_tree_languages');

        languageSelector.off('change');
        languageSelector.val(node.data && node.data.languages ? node.data.languages : null).trigger('change');
        languageSelector.on('change', (event: JQuery.ChangeEvent) => {
            if (node.data === null || typeof node.data === 'undefined') {
                node.data = {};
            }
            node.data.languages = jQuery(event.target).val();
            this.treeEditor.trigger('load_node.jstree', [{node: node, status: true}]);
        });
        languageSelector.select2();
    }

    /**
     *
     * @param parent
     * @param uuid
     * @param text
     * @param type
     */
    private createTreeNode(parent, uuid: string, text: string, type: string): any {
        const newNodeId = this.treeEditor.jstree(
            'create_node',
            parent,
            {
                "id":   uuid,
                "a_attr": {
                    "title" : "Pulsa botón derecho para ver las opciones",
                    "data-toggle" : "tooltip",
                    "data-selector" : "true"
                },
                "text": text,
                "icon" : this.getIconResourcePath(type)
            },
            "last",
            () => {
                this.treeEditor.jstree('open_node', parent);
            }
        );

        const newNode = this.treeEditor.jstree('get_node', newNodeId);
        if (newNode.data === null) {
            newNode.data = {};
        }
        newNode.data.type = type;

        return newNode;
    }

    /**
     *
     * @param type
     */
    private getIconResourcePath(type: string): string | null {

        let path = this.elem.find('#icon_path_' + type).val();

        if (typeof path == "string") {
            return path;
        }
        return null;
    }

    /**
     *
     * @param node
     * @param uuid
     * @param text
     */
    private createTreeNodeContainer(node, uuid: string, text: string): any {
        return this.createTreeNode(node, uuid, text, "container");
    }

    /**
     *
     * @param node
     * @param uuid
     */
    private createTreeNodeContent(node, uuid: string): any {
        let text = Translator.trans('chosen.noTreeItemSelected', {}, 'messages');
        return this.createTreeNode(node, uuid, text, "content");
    }

    /**
     * Contextual Menu right button
     * @param node
     */
    private getTreeContextMenuItems(node): any {
        const contextMenu: any = {};

        if (!(node.data.type === 'content' && node.children.length > 0)) {
            contextMenu.addContent   = {
                label:  Translator.trans('generic.addNewContent', {}, 'messages'),
                action: (obj) => {
                    const node = this.treeEditor.jstree('get_node', obj.reference);
                    const uuid = uuid4().toUpperCase();

                    this.createTreeNodeContent(node, uuid);
                }
            };
            contextMenu.addContainer = {
                label:  Translator.trans('generic.addNewContainer', {}, 'messages'),
                action: (obj) => {
                    const modal = this.elem.find('.modal-create-container');
                    modal.on('shown.bs.modal', () => {
                        modal.find('button.create-container').off('click')
                            .on('click', (e: JQuery.TriggeredEvent) => {
                                modal.modal('hide');

                                const type = jQuery(e.target).data('containerType');

                                this.modalContainerNew(type, obj);
                            });
                    });
                    modal.modal('show');
                }
            };
        }

        if (node.parent !== '#') {
            contextMenu.deleteItem = {
                label:  Translator.trans('generic.deleteSubtree', {}, 'messages'),
                action: (obj) => {
                    const node = this.treeEditor.jstree('get_node', obj.reference);
                    this.treeEditor.jstree().delete_node(node);
                }
            };
        }

        return contextMenu;
    }

    /**
     *
     * @param urlEditContainer
     */
    private modalContainer(urlEditContainer: string): Promise<containerEditAjaxResponse> {
        return new Promise((resolve: (response: containerEditAjaxResponse) => void) => {
            this.modalAjax.modal('show');

            this.modalAjaxBody.empty();
            this.modalAjaxBody.append(Spinner.get());

            jQuery.ajax({
                url:      urlEditContainer,
                complete: (jqXHR, textStatus) => {
                    this.modalContainerDrawForm(jqXHR.responseText, urlEditContainer, resolve);
                }
            });
        });
    }


    /**
     *
     * The async request back one form in html generated
     *
     * @param htmlForm
     * @param urlEditContainer
     * @param resolve
     */
    private modalContainerDrawForm(htmlForm: string, urlEditContainer: string, resolve: (response: containerEditAjaxResponse) => void) {

        this.modalAjaxBody.empty();
        this.modalAjaxBody.append(htmlForm);

        this.modalAjaxBody.find('select[data-toggle="chosen"]').customizedSelect2();
        this.modalAjaxBody.find('.advanced-file-manager').advancedUploader();
        this.modalAjaxBody.find('.row.locution').locutionManager();
        this.modalAjaxBody.find('[data-toggle="tags-editor"]').tagsEditor();
        this.modalAjaxBody.find('#geo-map-viewer').geoMapViewer();

        this.modalAjaxBody.find('.btn-cancel').on('click', (e: JQuery.TriggeredEvent) => {
            e.preventDefault();
            e.stopPropagation();

            this.modalAjax.modal('hide');
        });

        this.modalAjaxBody.find('form')
            .on('submit', (e: JQuery.SubmitEvent) => {
                e.preventDefault();
                e.stopPropagation();

                this.modalAjaxBody.find('.btn-submit .btn')
                    .prepend(Spinner.get({
                        position: "left",
                        size:     "small"
                    }))
                    .prop('disabled', true);

                /**
                 * Send data form async
                 */
                jQuery.ajax({
                    url:      urlEditContainer,
                    type:     'POST',
                    data:     jQuery(e.target).serialize(),
                    complete: (jqXHR, textStatus) => {
                        if (typeof jqXHR.responseJSON !== 'undefined') {
                            const promiseDetails = new Promise((resolve) => {
                                jQuery.ajax({
                                    url:      Routing.generate('container_data', {'containerId': jqXHR.responseJSON.id}),
                                    type:     'GET',
                                    complete: (jqXHR, textStatus) => {
                                        resolve(jqXHR.responseJSON);
                                    }
                                });
                            });

                            Promise.all([promiseDetails])
                                .then((value) => {
                                    let containerDetails;

                                    [containerDetails] = value;

                                    const typeString = Translator.trans('generic.' + containerDetails.item_type, {}, 'messages');
                                    const title      = containerDetails.title + ' <small>(' + typeString + ')</small>';

                                    resolve({id: containerDetails.id, title: title});

                                    this.modalAjax.modal('hide');
                                    this.modalAjaxBody.empty();
                                    Spinner.remove(this.modalAjaxBody.find('.btn-submit .btn'));
                                });
                        }
                        else if (typeof jqXHR.responseText !== 'undefined') {
                            this.modalContainerDrawForm(jqXHR.responseText, urlEditContainer, resolve);
                        }
                    }
                });
            });
    }

    /**
     *
     * @param node
     */
    private modalContainerEdit(node: any) {
        const url = Routing.generate('container_edit', {'containerId': node.data.itemId});

        this.modalContainer(url).then((response: containerEditAjaxResponse) => {
            this.treeEditor.jstree('rename_node', node, response.title);
        });
    }

    /**
     *
     * @param type
     * @param obj
     */
    private modalContainerNew(type, obj) {
        const url = Routing.generate('container_create', {'type': type});

        this.modalContainer(url).then((response: containerEditAjaxResponse) => {
            const node = this.treeEditor.jstree('get_node', obj.reference);
            const uuid = uuid4().toUpperCase();

            const newNode = this.createTreeNodeContainer(node, uuid, response.title);
            this.setTreeNodeItemId(newNode, response.id);
        });
    }

    /**
     *
     * @param node
     * @param id
     */
    private setTreeNodeItemId(node, id: string) {
        if (node.data === null || typeof node.data === 'undefined') {
            node.data = {};
        }
        node.data.itemId = id;
        this.treeEditor.trigger('load_node.jstree', [{node: node, status: true}]);
    }
}

interface containerEditAjaxResponse {
    id: string,
    title: string
}

export default ItemsTreeEditor;

declare global {
    interface JQuery {
        itemsTreeEditor(context?: string)
    }
}

(function ($) {
    $.fn.itemsTreeEditor = function(context: string){
        return this.each((index: number, elem: HTMLElement) => {
            return new ItemsTreeEditor(jQuery(elem), context);
        });
    }
})(jQuery);
