/**
 * @return {RequestHelper}
 * @constructor
 */

export default class RequestHelper {
    /**
     * @type {{head: string, post: string, get: string, delete: string}}
     */
    private methods = {
        delete: 'DELETE',
        get:    'GET',
        head:   'HEAD',
        post:   'POST'
    };

    /**
     * @param {string} method
     * @param {string} url
     * @param {XMLHttpRequestResponseType} [responseType]
     * @param {FormData} [data]
     * @param {function} [onload]
     * @param {function} [onerror]
     * @param {function} [onreadystatechange]
     * @param {function} [onprogress]
     */
    private static request(
        method: string,
        url: string,
        responseType: XMLHttpRequestResponseType,
        data,
        onload,
        onerror?,
        onreadystatechange?,
        onprogress?
    ) {
        const request = new XMLHttpRequest();
        request.open(method, url, true);
        if (responseType) {
            request.responseType = responseType;
        }
        request.onload             = onload;
        request.onerror            = onerror;
        request.onreadystatechange = onreadystatechange;
        if (request.upload) {
            request.upload.onprogress = onprogress;
        }
        request.onprogress = onprogress;
        request.send(data);
    }

    /**
     * @param {string} url
     * @return {jQuery.Deferred.promise}
     * @memberOf RequestHelper#
     */
    delete(url: string): Promise<XMLHttpRequest> {
        return new Promise((resolve, reject) => {
            RequestHelper.request(
                this.methods.delete,
                encodeURI(url),
                null,
                null,
                (event) => {
                    resolve(event.target);
                },
                (event) => {
                    reject(event.target);
                },
                null
            );
        });
    };

    get(url: string, responseType: XMLHttpRequestResponseType): Promise<XMLHttpRequest> {
        return new Promise((resolve, reject) => {
            RequestHelper.request(
                this.methods.get,
                encodeURI(url),
                responseType,
                null,
                event => {
                    resolve(event.target);
                },
                event => {
                    reject(event.target);
                },
                null
            );
        });
    };

    /**
     * @param {string} url
     * @param {XMLHttpRequestResponseType} [responseType]
     * @return {jQuery.Deferred.promise}
     * @memberOf RequestHelper#
     */
    head(url: string, responseType: XMLHttpRequestResponseType): Promise<XMLHttpRequest> {
        return new Promise((resolve, reject) => {
            RequestHelper.request(
                this.methods.head,
                encodeURI(url),
                responseType,
                null,
                (event) => {
                    if (event.target.readyState === event.target.DONE) {
                        resolve(event.target);
                    }
                },
                (event) => {
                    reject(event);
                }
            );
        });
    };

    post(url: string, data: object, responseType: XMLHttpRequestResponseType, onProgress?): Promise<XMLHttpRequest> {
        return new Promise((resolve, reject) => {
            const encodedUrl = encodeURI(url);

            const formData = new FormData();
            for (const field in data) {
                if (data.hasOwnProperty(field) && typeof data[field] !== 'undefined') {
                    formData.append(field, data[field]);
                }
            }

            RequestHelper.request(
                this.methods.post,
                encodedUrl,
                responseType,
                formData,
                (event) => {
                    resolve(event.target);
                },
                (event) => {
                    reject(event.target);
                },
                null,
                (event) => {
                    if (typeof onProgress !== 'undefined') {
                        onProgress(event);
                    }
                }
            );
        });
    };
}
