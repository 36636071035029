module StorageHelper {

    export class StorageItem{

        public key: string;
        public value: any;

        constructor(data: IStorageItem) {
            this.key    = data.key;
            this.value  = data.value;
        }
    }

    export interface IStorageItem {
        key: string;
        value: any;
    }

    export class SessionStorageWorker {

        public sessionStorageSupported: boolean;

        /**
         *
         */
        constructor() {
            this.sessionStorageSupported = typeof window['sessionStorage'] != 'undefined' && window['sessionStorage'] != null;
        }

        /**
         *
         * @param key
         * @param item
         */
        public add(key: string, item: any): void {
            if (!this.sessionStorageSupported) {
                return;
            }
            sessionStorage.setItem(key, item);
        }

        /**
         *
         */
        public getAllItems(): Array<StorageItem> {

            let list = new Array<StorageItem>();

            for (var i = 0; i < sessionStorage.length; i++) {
                let key   = sessionStorage.key(i);
                let value = sessionStorage.getItem(key);

                list.push(new StorageItem({
                    key: key,
                    value: value
                }));
            }

            return list;
        }

        /**
         *
         */
        public getAllValues(): Array<any> {

            let list = new Array<any>();

            for (var i = 0; i < sessionStorage.length; i++) {
                let key = sessionStorage.key(i);
                let value = sessionStorage.getItem(key);

                list.push(value);
            }

            return list;
        }

        /**
         *
         * @param key
         */
        public get(key: string): string {
            if (!this.sessionStorageSupported) {
                return null;
            }
            let item = sessionStorage.getItem(key);

            return item;
        }

        /**
         *
         * @param key
         */
        public remove(key: string): void {
            if (!this.sessionStorageSupported) {
                return;
            }
            sessionStorage.removeItem(key);
        }

        /**
         *
         */
        public clear(): void {
            if (!this.sessionStorageSupported) {
                return;
            }
            sessionStorage.clear();
        }
    }
}

export default StorageHelper;