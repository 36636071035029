import Routing from "./routing";
import Spinner from "./spinner";

class SendNotification {

    private modal: JQuery<HTMLElement>;
    private notificationId: string;
    private loader: boolean = false;
    private confirmButton: JQuery<HTMLElement>;

    /**
     *
     * @param elem
     */
    constructor(elem: JQuery<HTMLElement>) {

        this.notificationId = <string> elem.find('#notification-id').val();
        this.modal          = jQuery(`#modal-notification-${this.notificationId}`);
        this.confirmButton  = jQuery(`#send-btn-${this.notificationId}`);
        this.addEvents();
    }

    /**
     *
     * @private
     */
    private addEvents(): void
    {
        this.confirmButton.on('click', () => {
            this.httpRequest(this.notificationId, this.getLanguagesFromDOM());
        });

        this.modal.on('hidden.bs.modal', () => {
            Spinner.remove(this.confirmButton);
            this.confirmButton.prop('disabled', false);
            this.loader = false;
        });
    }

    /**
     *
     * @private
     */
    private getLanguagesFromDOM(): Array<string>
    {
        const languages: Array<string> = [];

        const checkboxList: JQuery<HTMLElement> = this.modal.find('input[type="checkbox"]');

        for (let i: number = 0; i < checkboxList.length; i++) {
            if (jQuery(checkboxList[i]).prop('checked')) {
                languages.push(<string> jQuery(checkboxList[i]).val());
            }
        }

        return languages;
    }

    /**
     *
     * @param notificationId
     * @param languages
     * @private
     */
    private httpRequest(notificationId: string, languages: Array<string>): void
    {
        if (this.loader) return;
        if (!languages.length) return;

        this.confirmButton.prepend(Spinner.get({position: 'left', size: 'small'}));
        this.confirmButton.prop('disabled', true);
        this.loader = true;

        jQuery.ajax({
            url: Routing.generate('notification_send', {}),
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: JSON.stringify({
                notification: notificationId,
                languages: languages
            }),
            complete: (jqXHR) => {
                this.loader = false;
                Spinner.remove(this.confirmButton);
                this.confirmButton.prop('disabled', false);
                this.modal.modal('hide');
            },
            error: function(jqXHR) {
                console.error(jqXHR);
            }
        });
    }
}

export default SendNotification;

declare global {
    interface JQuery {
        sendNotification();
    }
}


(function ($) {

    $.fn.sendNotification = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let sendNotification = jQuery(elem).data('sendNotification');
            if (typeof sendNotification === 'undefined') {
                sendNotification = new SendNotification(jQuery(elem));
                jQuery(elem).data('sendNotification', sendNotification);
            }
            return sendNotification;
        });
    }

})(jQuery);
