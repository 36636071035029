import SubtitleGenerator from './subtitle-generator';
import SubtitlesParser from "./subtitles-parser";

class SubtitlesEditor {

    private elementContext: JQuery<HTMLElement>;
    private editContentNode: JQuery<HTMLElement>;
    private spinnerNode: JQuery<HTMLElement>;
    private prototypeNode: JQuery<HTMLElement>;
    private listSubtitlesNode: JQuery<HTMLElement>;
    private parentAccessibilityNode: JQuery<HTMLElement>;
    private subtitleGeneratorInstance: SubtitleGenerator;
    private subtitles: Array<{ duration: number, subtitle: string }>;
    private IntervalHandlerSeconds: any;
    private readonly parentElementId: string;
    private modalExportSubtitles: JQuery<HTMLElement>;
    private modalImportSubtitles: JQuery<HTMLElement>;
    private subtitlesParser: SubtitlesParser;

    /**
     * @param elem
     */
    public constructor(elem: JQuery<HTMLElement>) {
        this.elementContext            = elem;
        this.editContentNode           = elem.find('#content-subtitles');
        this.spinnerNode               = elem.find('#loader');
        this.prototypeNode             = elem.find('.new-subtitle-prototype').data('prototype');
        this.listSubtitlesNode         = elem.find('#list-subtitles');
        this.parentElementId           = elem.attr('id');
        this.parentAccessibilityNode   = elem.parent().parent();
        this.subtitleGeneratorInstance = new SubtitleGenerator();
        this.subtitles                 = [];
        this.modalExportSubtitles      = elem.find('#' + this.parentElementId + '_export_srt_subtitles');
        this.modalImportSubtitles      = elem.find('#' + this.parentElementId + '_import_srt_subtitles');
        this.subtitlesParser           = new SubtitlesParser();

        this.loadSubtitlesToContext();
        this.addEvents();
    }

    /**
     * @return void
     */
    private addEvents(): void {

        this.elementContext.find('.add-subtitles').on('click', () => {
            this.elementContext.find('#' + this.parentElementId + '_selection_type_locution').modal('show');
        });

        this.elementContext.find('.create-subtitle').on('click', () => {
            this.addSubtitleEventHandler();
        });

        this.elementContext.find('.import-subtitles').on('click', () => {
            this.modalImportSubtitles.modal('show');
        });

        this.elementContext.find('.export-subtitles').on('click', () => {
            this.exportSubtitles();
        });

        this.modalImportSubtitles.find('#srt_file_subitles').on('change', (event: JQuery.TriggeredEvent) => {

            let reader = new FileReader();

            reader.onload = () => {
                this.importSubtitles(reader.result.toString());
                this.modalImportSubtitles.modal('hide');
            };

            if (!event.target.files.length) {
                return;
            }
            if (!event.target.files[0].name.match(/\.(srt|SRT)$/)) {
                throw 'PATTERN FILE FORMAT IS INVALID';
            }
            reader.readAsText(event.target.files[0], 'ISO-8859-1');
        });

        this.elementContext.find('#' + this.parentElementId + '_confirm_generate').on('click', () => {
            this.elementContext.find('#' + this.parentElementId + '_selection_type_locution').modal('hide');
            this.generateSubtitlesEventHandler();
        });
    }

    /**
     * @return void
     */
    private addSubtitleEventHandler(): void {

        let newSubtitle: { duration: number, subtitle: string } = {duration: 0, subtitle: ''};
        let index: number                                       = this.subtitles.length ? this.subtitles.length : 0;

        if (!this.checkExistsSubtitles()) {
            this.addNodeAudioPlayer(this.getAudioPathResource().toString());
            this.addEventsMediaAudioPlayer();
        }

        this.subtitles.push(newSubtitle);
        this.createSubtitlesNodeElements(newSubtitle, index);
    }

    /**
     * @param index
     * @return void
     */
    private addEventsToNodeGrill(index: number): void {

        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_subtitle')
            .on('change', (event: JQuery.TriggeredEvent) => {
                this.subtitles[index].subtitle = event.currentTarget.value;
            })
            .on('keyup', (event: JQuery.TriggeredEvent) => {
                this.updateElementNumberCharacter(jQuery(event.currentTarget));
            });

        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_duration')
            .on('change', (event: JQuery.TriggeredEvent) => {

                let index: number = this.getIndexSubtitleObjectByInputId(event);

                this.subtitles[index].duration = Number(event.currentTarget.value);

                this.cancelWhenChangesValues();
            });

        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_delete_btn')
            .attr('data-index', index.toString())
            .on('click', (event: JQuery.TriggeredEvent) => {
                this.deleteGrillHandlerEvent(index);
            });

        this.elementContext.find('#target tr').eq(index).find('.move-subtitle')
            .on('click', (event: JQuery.TriggeredEvent) => {
                this.moveSubtitleEventHandler(event, index);
            });
    }

    private moveSubtitleEventHandler(event: JQuery.TriggeredEvent, index: number): void {

        let newIndex: number,
            direction: string = jQuery(event.currentTarget).data('direction'),
            indexRow: number  = jQuery(event.currentTarget).parents('tr').index();

        if (index != indexRow) {
            throw 'ERROR: INDEX NOT EQUAL';
        }

        newIndex = this.getNewIndex(direction, index);

        if (newIndex == index) {
            return;
        }

        this.moveElementArraySubtitle(index, newIndex, this.subtitles[index]);
        this.cancelWhenChangesValues()
        this.clearTableNodeSubtitles();

        for (let i: number = 0; i < this.subtitles.length; i++) {
            this.createSubtitlesNodeElements(this.subtitles[i], i);
        }
    }

    /**
     * @param direction
     * @param index
     * @return number
     */
    private getNewIndex(direction: string, index: number): number {
        switch (direction) {
            case 'down':
                return this.subtitles.length - 1 > index ? index + 1 : index;
            case 'up':
                return index > 0 ? index - 1 : index;
        }
    }

    /**
     * @param index
     * @param newIndex
     * @param value
     * @return void
     */
    private moveElementArraySubtitle(index: number, newIndex: number, value: { subtitle: string, duration: number }): void {
        this.subtitles.splice(index, 1);
        this.subtitles.splice(newIndex, 0, value);
    }

    /**
     * @return void
     */
    private clearTableNodeSubtitles(): void {
        this.elementContext.find('#target').empty();
    }


    /**
     * @param index
     * @return void
     */
    private addValuesSubtitlesToObject(index: number): void {

        let duration: any = this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_duration').val();
        let text: any     = this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_subtitle').val();

        this.subtitles.push({duration: Number(duration), subtitle: text});
    }

    /**
     * @param subtitle
     * @param index
     * @return void
     */
    private createSubtitlesNodeElements(subtitle: { duration: number, subtitle: string }, index: number): void {
        let element: string = this.prototypeNode.toString().replace(/__name__/g, index.toString());

        this.elementContext.find('#target').append(element);

        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_position').val(index + 1);
        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_subtitle').val(subtitle.subtitle)
        this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_duration').val(subtitle.duration);

        this.addEventsToNodeGrill(index);
        this.updateElementNumberCharacter(this.elementContext.find('#' + this.parentElementId + '_' + index.toString() + '_subtitle'));
    }

    /**
     * @return void
     */
    private generateSubtitlesEventHandler(): void {

        if (!this.checkIssetLocutionTextAndAudio()) {
            this.showModalMessageInfo();
            return;
        }

        if (this.checkExistsSubtitles()) {
            this.unsetSubtitlesData();
        }

        this.addNodeAudioPlayer(this.getAudioPathResource().toString());
        this.addEventsMediaAudioPlayer();

        this.setGeneratedSubtitlesByTextLocution(this.getTextLocutionFromNode().toString());

        for (let i: number = 0; i < this.subtitles.length; i++) {
            this.createSubtitlesNodeElements(this.subtitles[i], i);
        }
    }

    /**
     * @return boolean
     */
    private checkIssetLocutionTextAndAudio(): boolean {
        let url: string          = this.getAudioPathResource().toString(),
            textLocution: string = this.getTextLocutionFromNode().toString();

        if (!url.length || !textLocution.length) {
            return false;
        }
        return true;
    }

    /**
     * @return void
     */
    private unsetSubtitlesData(): void {
        this.subtitles = [];
        this.clearTableNodeSubtitles();
    }

    /**
     *
     */
    private getAudioPathResource(): any {
        let inputFileAudioId: any = this.parentAccessibilityNode.attr('id').replace('accessibility_collapse_body', 'accessibility_locution'),
            urlResource: any      = this.parentAccessibilityNode.find('#' + inputFileAudioId).val();

        return urlResource;
    }

    /**
     * @return void
     */
    private loadSubtitlesToContext(): void {

        let elementsRows: any = this.elementContext.find('#target tr');

        if (elementsRows.length) {

            this.addNodeAudioPlayer(this.getAudioPathResource().toString());
            this.addEventsMediaAudioPlayer();

            for (let i: number = 0; i < elementsRows.length; i++) {
                this.addEventsToNodeGrill(i);
                this.addValuesSubtitlesToObject(i);
                this.updateElementNumberCharacter(this.elementContext.find('#' + this.parentElementId + '_' + i.toString() + '_subtitle'));
            }
        }
    }

    /**
     * @return void
     */
    private showModalMessageInfo(): void {
        this.elementContext.find('#' + this.parentElementId + '_message_empty').modal('show');
    }

    /**
     * @param url
     * @return jQuery<HTMLElement>
     */
    private createAudioPlayer(url: string): any {
        return jQuery('<audio controls>').attr('id', this.parentElementId + 'audio_player').attr('controlslist', 'nodownload').append('<source src="/' + url + '" type="audio/mp3">');
    }

    /**
     * @param string url
     * @return void
     */
    private addNodeAudioPlayer(url: string): void {
        if (url) {
            this.elementContext.find('#loader-locution').empty().append(this.createAudioPlayer(url));
        }
    }

    /**
     * @return string
     */
    private getTextLocutionFromNode(): any {
        let inputTextLocutionId = this.parentAccessibilityNode.attr('id').replace('accessibility_collapse_body', 'accessibility_locutionText'),
            textLocution        = this.parentAccessibilityNode.find('#' + inputTextLocutionId).val();

        return textLocution;
    }

    /**
     * @return boolean
     */
    private checkExistsSubtitles(): boolean {
        if (this.elementContext.find('#target tr').length) {
            return true;
        }
        return false;
    }

    /**
     * @param locution
     * @return void
     */
    private setGeneratedSubtitlesByTextLocution(locution: string): void {
        let valueSelectedLocution: any              = this.elementContext.find('.type-locution').val(),
            serializeObject: { timeSubStr: number } = this.serializeJsonObject(valueSelectedLocution);

        this.subtitleGeneratorInstance.setDurationMsPerCharacter(serializeObject.timeSubStr);
        this.subtitleGeneratorInstance.createSubtitles(locution);
        this.subtitles = this.subtitleGeneratorInstance.getSubtitles();
    }

    /**
     * @param object
     * @return Array
     */
    private serializeJsonObject(object: string): any {
        if (!object || typeof object !== 'string') {
            throw 'ERROR: EMPTY OBJECT TO SERIALIZE';
        }
        return JSON.parse(object);
    }

    /**
     * @param ncurrentTarget
     * @return void
     */
    private updateElementNumberCharacter(currentTarget: JQuery<HTMLElement>): void {
        let lengthSubtitle: number = currentTarget.val().toString().length;

        currentTarget.parent().parent().find('.count-characters b').html(lengthSubtitle.toString());
    }

    /**
     * @param index
     * @return void
     */
    private deleteGrillHandlerEvent(index: number): void {
        this.pauseAudioPlayer();
        this.elementContext.find('#target tr').remove();
        this.subtitles.splice(index, 1);
        for (let i: number = 0; i < this.subtitles.length; i++) {
            this.createSubtitlesNodeElements(this.subtitles[i], i);
        }
    }

    /**
     * @return void
     */
    private cancelWhenChangesValues(): void {
        this.pauseAudioPlayer();
        this.toggleCSSGrillsHandler();
    }

    /**
     * @return void
     */
    private pauseAudioPlayer(): void {
        let audioPlayer: any = <any>document.getElementById(this.parentElementId + 'audio_player');
        if (audioPlayer) {
            audioPlayer.pause();
        }
    }

    private getIndexSubtitleObjectByInputId(event: JQuery.TriggeredEvent): number {
        let inputDurationId: string = event.currentTarget.id,
            valueInputPosition      = this.elementContext.find('#' + inputDurationId.replace('duration', 'position')).val();

        if (!valueInputPosition) {
            throw 'ERROR: INPUT POSITION EMPTY';
        }
        return Number(valueInputPosition) - 1;
    }

    /**
     * @return void
     */
    private addEventsMediaAudioPlayer(): void {
        this.elementContext.find('#' + this.parentElementId + 'audio_player').on('play', (event: JQuery.TriggeredEvent) => {
            this.handlerEventAudioPlayer(event);
        });

        this.elementContext.find('#' + this.parentElementId + 'audio_player').on('pause', () => {
            this.unsetIntervalHandlerSeconds();
        });
    }

    private handlerEventAudioPlayer(event: JQuery.TriggeredEvent): void {
        let secondsIncrease: Array<number> = this.getRangesSecondsOfSubtitles();

        this.setIntervalHandlerSeconds(
            event,
            secondsIncrease
        );
    }

    /**
     * @param event
     * @param secondsIncrease
     * @return void
     */
    private setIntervalHandlerSeconds(event: JQuery.TriggeredEvent, secondsIncrease: Array<number>): void {

        this.IntervalHandlerSeconds = setInterval(() => {

            let currentTime: number  = Math.floor(Number(event.currentTarget.currentTime)),
                durationTime: number = Math.floor(Number(event.currentTarget.duration)),
                index: number        = this.checkIndexProgressBySecondTime(currentTime, durationTime, secondsIncrease);

            this.toggleCSSGrillsHandler(index);

        }, 1000);
    }

    /**
     * @return void
     */
    private unsetIntervalHandlerSeconds(): void {
        clearInterval(this.IntervalHandlerSeconds);
    }

    /**
     * @param index
     * @return void
     */
    private toggleCSSGrillsHandler(index?: number): void {
        this.elementContext.find('#target').children().removeClass('grill-subtitle-selected');
        if (typeof index == 'number') {
            this.elementContext.find('#target tr').eq(index).addClass('grill-subtitle-selected');
        }
    }

    /**
     * @param currentTime
     * @param durationTime
     * @param secondsIncrease
     * @return number
     */
    private checkIndexProgressBySecondTime(currentTime: number, durationTime: number, secondsIncrease: Array<number>): number {
        for (let i: number = 0; i < secondsIncrease.length; i++) {

            let currentRange: number = secondsIncrease[i],
                backRange: number    = i > 0 ? secondsIncrease[i - 1] : 0;

            if (currentTime < currentRange && currentTime >= backRange) {
                return i;
            }
        }
        return secondsIncrease.length - 1;
    }

    /**
     * @return array
     */
    private getRangesSecondsOfSubtitles(): Array<number> {
        let secondsIncrease: Array<number> = [];

        for (let i: number = 0; i < this.subtitles.length; i++) {

            let increment: number = secondsIncrease.length ? secondsIncrease[secondsIncrease.length - 1] : 0;

            secondsIncrease.push(increment + this.subtitles[i].duration);
        }
        return secondsIncrease;
    }

    /**
     *
     * @param textPlain
     */
    private importSubtitles(textPlain: string): void {
        if (!this.subtitlesParser.validateSRT(textPlain)) {
            console.error('ERROR SRT FORMAT');
        }
        this.clearTableNodeSubtitles()

        this.subtitles = this.subtitlesParser.toArray(textPlain);

        for (let i: number = 0; i < this.subtitles.length; i++) {
            this.createSubtitlesNodeElements(this.subtitles[i], i);
        }
    }

    private exportSubtitles(): void {
        let subtitles: Array<{ duration: number, position: number, subtitle: string }> | null,
            base64encoded: string,
            linkNode: JQuery<HTMLElement>,
            textareaPreview: JQuery<HTMLElement>,
            subtitlesSRTFormatted: string;

        subtitles = this.getSubtitlesFromHTMLNodes();

        if (!subtitles.length) {
            return;
        }
        this.modalExportSubtitles.modal('show');

        textareaPreview       = this.modalExportSubtitles.find('#srt-preview');
        subtitlesSRTFormatted = this.subtitlesParser.toSRTFormat(subtitles)
        base64encoded         = btoa(subtitlesSRTFormatted);
        linkNode = this.modalExportSubtitles.find('#link-download'),

            textareaPreview.val(subtitlesSRTFormatted);
        linkNode.attr('download', this.subtitlesParser.getSRTFilename());
        linkNode.attr('href', 'data:text/plain;base64,' + base64encoded);
        linkNode.removeClass('d-none');

        linkNode.on('click', () => {
            this.modalExportSubtitles.modal('hide');
            textareaPreview.empty();
        });
        this.modalExportSubtitles.find('.modal-body').append(linkNode);
    }

    /**
     *
     */
    private getSubtitlesFromHTMLNodes(): Array<{ duration: number, position: number, subtitle: string }> {
        let rows                                                                       = this.listSubtitlesNode.find('tbody tr');
        let subtitles: Array<{ duration: number, position: number, subtitle: string }> = [];

        for (let i = 0; i < rows.length; i++) {

            let duration: string = jQuery(rows[i]).find('[id$="_duration"]').val().toString(),
                position: string = jQuery(rows[i]).find('[id$="_position"]').val().toString(),
                subtitle: string = jQuery(rows[i]).find('[id$="_subtitle"]').val().toString();

            subtitles.push({
                duration: parseInt(duration),
                position: parseInt(position),
                subtitle: subtitle
            });
        }
        return subtitles;
    }
}

declare global {
    interface JQuery {
        subtitlesEditor();
    }
}

(function ($) {

    $.fn.subtitlesEditor = function () {
        return this.each((index: number, elem: HTMLElement) => {

            let subtitlesEditor = jQuery(elem).data('subtitlesEditor');
            if (typeof subtitlesEditor === 'undefined') {
                subtitlesEditor = new SubtitlesEditor(jQuery(elem));
                jQuery(elem).data('subtitlesEditor', subtitlesEditor);
            }

            return subtitlesEditor;
        });
    }
})(jQuery);
