import StorageHelper from './storage-helper';

class RestoreTabSelected {

    private formHTML: JQuery<HTMLElement>;
    private StorageWorker: StorageHelper.SessionStorageWorker;
    private readonly dataStorageIdentifier: string;

    /**
     *
     * @param elem
     */
    constructor(elem: JQuery<HTMLElement>) {

        this.formHTML               =   elem;
        this.dataStorageIdentifier  =   'A72821854E970948E674313DF5FCA7B0';

        this.init();
    }

    /**
     *
     */
    private init(): void {

        let tabWidgets = this.getTabsWidget();

        if (!tabWidgets) {
            return;
        }
        this.StorageWorker = new StorageHelper.SessionStorageWorker();

        for (let i: number = 0;i < tabWidgets.length; i++) {
            this.restoreSelectionInMemory(tabWidgets[i]);
        }
        this.addEventToForm(tabWidgets);
    }

    /**
     *
     */
    private getTabsWidget()
    {
        return this.formHTML.find('.tabs-collection-widget');
    }

    /**
     *
     * @param tabWidgets
     */
    private addEventToForm(tabWidgets): void {
        this.formHTML.on('submit', () => {

           for (let i: number = 0; i < tabWidgets.length; i++) {

               let tabs = jQuery(tabWidgets[i]).find('.nav-link');

               for (let e: number = 0; e < tabs.length; e++) {
                    if (jQuery(tabs[e]).attr('aria-selected') == 'true') {
                        this.setTabSelectedInMemory(
                            this.getIdFromHTMLElement(tabWidgets[i]),
                            this.getIdFromHTMLElement(tabs[e])
                        );
                        break;
                    }
               }
           }
        });
    }

    /**
     *
     * @param tabWidgetIdentifier
     * @param tabIdentifierId
     */
    private setTabSelectedInMemory(tabWidgetIdentifier: string, tabIdentifierId: string) {

        let objectParsedFromStorage = this.getDataFromStorage();
        let newObjectStorage        = {};

        if (objectParsedFromStorage) {
            newObjectStorage = objectParsedFromStorage;
        }
        newObjectStorage[tabWidgetIdentifier] = tabIdentifierId;

        this.StorageWorker.add(this.dataStorageIdentifier, JSON.stringify(newObjectStorage));
    }

    /**
     *
     * @param elem
     */
    private getIdFromHTMLElement(elem: HTMLElement): string {
        return jQuery(elem).attr('id');
    }

    /**
     *
     */
    private getDataFromStorage(): object{
        let objectStorage = this.StorageWorker.get(this.dataStorageIdentifier);

        if (objectStorage === null) {
            return null;
        }
        return JSON.parse(objectStorage);
    }

    /**
     *
     * @param tabWidget
     */
    private restoreSelectionInMemory(tabWidget: HTMLElement): void {

        let tabWidgetId                     = this.getIdFromHTMLElement(tabWidget);
        let objectParsedFromStorage         = this.getDataFromStorage();

        if (objectParsedFromStorage === null) {
            return;
        }
        if (!objectParsedFromStorage.hasOwnProperty(tabWidgetId)) {
            return;
        }
        jQuery('#' + objectParsedFromStorage[tabWidgetId]).tab("show");
    }
}

export default RestoreTabSelected;

declare global {
    interface JQuery {
        restoreTabSelected()
    }
}

(function ($) {
    $.fn.restoreTabSelected = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let restoreTabSelected = jQuery(elem).data('restoreTabSelected');
            if (typeof restoreTabSelected === 'undefined') {
                restoreTabSelected = new RestoreTabSelected(jQuery(elem));
                jQuery(elem).data('restoreTabSelected', restoreTabSelected);
            }
            return restoreTabSelected;
        });
    }
})(jQuery);
