import * as THREE from "three";
import GLTFLoader from 'three-gltf-loader';
import {AmbientLight, PerspectiveCamera, PointLight, Scene, WebGLRenderer} from "three";
import OrbitControls from 'three-orbit-controls';

class Model3dRenderer {

    private lightColorDefault: number = 0xffffff;
    private backgroundColorDefault: number = 0xe9ecef;
    private defaultWidth: number = 600;
    private defaultHeight: number = 320;
    private scene: Scene;
    private camera: PerspectiveCamera;
    private pointLight: PointLight;
    private ambientLight: AmbientLight;
    private renderer: WebGLRenderer;
    private orbitControls: any;
    private requestAnimationFrame: any;

    public constructor(
        private element: HTMLElement
    ) {
    }

    public previewObject(filepath: string): void {
        this.setUpScene();
        this.setUpCamera();
        this.setUpLights();

        this.scene.add(this.pointLight);
        this.scene.add(this.ambientLight);

        this.setUpRenderer();
        this.setUpControls();

        this.element.append(this.renderer.domElement);
        this.loadHttpObject(filepath);
        this.startRendererHandler();
    }

    public destroy(): void {
        window.cancelAnimationFrame(this.requestAnimationFrame);
        this.renderer.domElement.remove();
    }

    public setUpScene(): void {
        this.scene = new THREE.Scene();
    }

    private setUpCamera(): void {
        this.camera = new THREE.PerspectiveCamera(80, this.defaultWidth / this.defaultHeight, 0.1, 1000);
        this.camera.position.set(5, 5, 5);
        this.camera.aspect = 16/9;
        this.camera.updateProjectionMatrix();
    }

    private setUpLights(): void {
        this.pointLight = new THREE.PointLight(this.lightColorDefault, 20, 200);
        this.pointLight.position.set(4, 30, -20);
        this.ambientLight = new THREE.AmbientLight(this.lightColorDefault, 1.2);
        this.ambientLight.position.set(30, -10, 30);
    }

    private setUpRenderer(): void {
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setClearColor(this.backgroundColorDefault);
        this.renderer.setSize(this.defaultWidth, this.defaultHeight);
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.gammaInput = true;
        this.renderer.gammaOutput = true;
        this.renderer.shadowMap.enabled = true;
    }

    private setUpControls(): void {
        this.orbitControls =  new (OrbitControls(THREE))(this.camera, this.renderer.domElement);
        this.orbitControls.rotateSpeed = 0.2;
        this.orbitControls.zoomSpeed = 1;
        this.orbitControls.minDistance = 1;
        this.orbitControls.maxDistance = 50;
        this.orbitControls.enableDamping = true;
        this.orbitControls.dampingFactor = 0.05;
        this.orbitControls.minPolarAngle = 0;
        this.orbitControls.maxPolarAngle = Math.PI / 2;
    }

    private loadHttpObject(filePath: string): void {
        const loader: GLTFLoader = new GLTFLoader();
        loader.load(
            window.location.origin + '/' + filePath,
            (data) => {
                data.scene.position.set(0,0, 0);
                this.scene.add(data.scene);
                this.renderer.render(this.scene, this.camera);
            },
            null,
            (error: ErrorEvent) => console.error(error)
        );
    }

    private startRendererHandler(): void {
        const handler = () => {
            this.requestAnimationFrame = requestAnimationFrame(handler);
            this.orbitControls.update();
            this.renderer.render(this.scene, this.camera);
        };
        handler();
    }
}

export default Model3dRenderer;