import RequestHelper from './request-helper';
import ThreeJSHelper, {AnimationStatus} from './threejs-helper';
import RecreaAPI from "./recrea-api";
import * as THREE from 'three';

interface ObjectData {
    cameras: ObjectDataStruct,
    cloud: ObjectDataStruct,
    mesh: ObjectDataStruct,
    model: ObjectDataStruct,
    normalmap: ObjectDataStruct,
    texture: ObjectDataStruct,
}

interface ObjectDataStruct {
    animated: AnimationStatus,
    contentDisposition: RegExp,
    fakeObject?: boolean,
    itemSelector: JQuery.Selector,
    model?: ThreeJSHelper.ModelType
    pointSize?: number,
    response: XMLHttpRequestResponseType,
}

class RecreaManager {
    private container: JQuery<HTMLElement>;
    private defaultProjectConfig          = {
        'match/accuracy':     2,
        'match/kplimit':      40000,
        'match/tplimit':      20000,
        'match/bbox':         true,
        'cloud/source':       'dense',
        'cloud/format':       'ply',
        'cloud/quality':      3,
        'cloud/filtering':    2,
        'model/source':       'dense',
        'model/surface':      '3d',
        'mesh/faces':         'high',
        'model/quads':        false,
        'model/fill_holes':   true,
        'model/smooth':       false,
        'atlas/atlas_height': 1024,
        'atlas/fill_holes':   true,
        'atlas/blending':     'mosaic',
        'normal/size':        1024,
        '3d/format':          'gltf',
        '3d/embed':           true,
        '3d/fwd':             '-Z',
        '3d/up':              'Y',
    };
    private objectData: ObjectData        = {
        cameras:   {
            itemSelector:       '#alignment-manager',
            response:           'json',
            model:              ThreeJSHelper.ModelType.cloud,
            pointSize:          1,
            fakeObject:         true,
            animated:           AnimationStatus.disabled,
            contentDisposition: /.*/
        },
        cloud:     {
            itemSelector:       '#cloud-manager',
            response:           'arraybuffer',
            model:              ThreeJSHelper.ModelType.cloud,
            animated:           AnimationStatus.enabled,
            contentDisposition: /.*/
        },
        mesh:      {
            itemSelector:       '#mesh-manager',
            response:           'arraybuffer',
            model:              ThreeJSHelper.ModelType.mesh,
            animated:           AnimationStatus.enabled,
            contentDisposition: /.*/
        },
        texture:   {
            itemSelector:       '#texture-manager',
            response:           'blob',
            animated:           AnimationStatus.forbidden,
            contentDisposition: /.*/
        },
        normalmap: {
            itemSelector:       '#normalmap-manager',
            response:           'blob',
            animated:           AnimationStatus.forbidden,
            contentDisposition: /.*/
        },
        model:     {
            itemSelector:       '#model-manager',
            response:           'arraybuffer',
            model:              ThreeJSHelper.ModelType.gltf,
            animated:           AnimationStatus.enabled,
            contentDisposition: /.*\.gltf.*/
        }
    };
    private picturesListRowTemplate: string;
    private projectData: object;
    private projectId: string;
    private readonly recreaAPI: RecreaAPI = new RecreaAPI();
    private requestHelper: RequestHelper  = new RequestHelper();
    private threeJSHelper: ThreeJSHelper;

    constructor(elem: JQuery) {
        this.container = elem;

        if (this.container.length > 0) {
            this.projectId = elem.data('recreaId');

            this.loadProjectData(true).then(() => {
                this.init();
            });

            const previewModelContainer = this.container.find('div#preview-model');
            const previewImageContainer = this.container.find('div#preview-image');
            this.threeJSHelper          = new ThreeJSHelper(previewModelContainer[0], previewImageContainer[0], false);
            this.threeJSHelper.onAnimated((animationStatus: AnimationStatus) => {
                this.threeJSAnimated(animationStatus);
            });
            this.threeJSHelper.init();

            this.container.find('#play-animation').on('click', () => {
                this.threeJSHelper.setAnimated(AnimationStatus.enabled);
            });
            this.container.find('#pause-animation').on('click', () => {
                this.threeJSHelper.setAnimated(AnimationStatus.disabled);
            });
        }
        else {
            console.warn('Empty recrea manager elem');
        }
    }

    private static getFieldValue(field: JQuery): string | number | string[] | undefined {
        let value = field.val();

        if (field.attr('type') === 'checkbox') {
            value = field.is(':checked') ? 1 : 0;
        }

        return value;
    }

    private static removeEmpty(data: Array<any> | object) {
        for (const item in data) {
            if (data.hasOwnProperty(item)) {
                if (typeof data[item] === 'string' && data[item] === '') {
                    delete data[item];
                }
            }
        }
    }

    private static setFieldValue(field: JQuery, value): void {
        if (field.attr('type') === 'checkbox') {
            field.prop('checked', value);
        }
        else {
            field.val(value);
        }
    }

    private static testPowerOf2(value: number): boolean {
        return value && (value & (value - 1)) === 0;
    }

    private checkObject(endpoint: RecreaAPI.Endpoints): Promise<XMLHttpRequest> {
        return new Promise((resolve) => {
            const url     = this.recreaAPI.getAPIURL(this.projectId, endpoint);
            const promise = this.requestHelper.head(url, this.objectData[endpoint].response);

            promise.then((event: XMLHttpRequest) => {
                    if (url === event.responseURL) {
                        if (event.status === 200
                            && this.objectData[endpoint].contentDisposition.test(event.getResponseHeader('Content-Disposition'))) {
                            this.enableDownload(endpoint);
                        }
                        else {
                            this.disableDownload(endpoint);

                            if (event.status === 501) {
                                const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);
                                htmlElement.css({opacity: 0.6});
                                htmlElement.find('fieldset').prop('disabled', true);
                                htmlElement.find('button').prop('disabled', true);
                            }
                        }
                    }
                    else {
                        this.loadingProgressShow(event.responseURL, endpoint);
                    }

                    resolve(event);
                },
                (event) => {
                    this.disableDownload(endpoint);

                    const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);
                    htmlElement.css({opacity: 0.6});
                    htmlElement.find('fieldset').prop('disabled', true);
                    htmlElement.find('button').prop('disabled', true);

                    resolve(event);
                });
        });
    }

    private checkResponse(response: XMLHttpRequest, endpoint: RecreaAPI.Endpoints) {
        let available = false;

        if (response instanceof XMLHttpRequest) {
            available = (response.responseURL === this.recreaAPI.getAPIURL(this.projectId, endpoint)
                && response.status === 200
                && this.objectData[endpoint].contentDisposition.test(response.getResponseHeader('Content-Disposition')));
        }

        return available;
    }

    private disableDownload(endpoint: RecreaAPI.Endpoints, generating: boolean = false): void {
        const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);

        htmlElement.find('button.generate').prop('disabled', false);
        htmlElement.find('button.generate').find('.generate').show();
        htmlElement.find('button.generate').find('.regenerate').hide();
        htmlElement.find('button.generate').find('.generating').hide();
        htmlElement.find('button.download').prop('disabled', true);
        htmlElement.find('button.download').hide();
        htmlElement.find('button.preview').prop('disabled', true);
        htmlElement.find('button.preview').hide();

        if (generating) {
            htmlElement.find('button.generate').prop('disabled', true);
            htmlElement.find('button.generate').find('.generate').hide();
            htmlElement.find('button.generate').find('.regenerate').hide();
            htmlElement.find('button.generate').find('.generating').show();
        }
    }

    private downloadObject(endpoint: RecreaAPI.Endpoints): void {
        const url = this.recreaAPI.getAPIURL(this.projectId, endpoint);

        const iframe = jQuery('<iframe>');
        iframe
            .attr('src', url)
            .height(0)
            .width(0);
        jQuery('body').append(iframe);
    }

    private enableDownload(endpoint: RecreaAPI.Endpoints): void {
        const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);

        htmlElement.find('button.generate').prop('disabled', false);
        htmlElement.find('button.generate').find('.generate').hide();
        htmlElement.find('button.generate').find('.regenerate').show();
        htmlElement.find('button.generate').find('.generating').hide();
        htmlElement.find('button.download').prop('disabled', false);
        htmlElement.find('button.download').show();
        htmlElement.find('button.preview').prop('disabled', false);
        htmlElement.find('button.preview').show();
    }

    private getObject(endpoint: RecreaAPI.Endpoints, maxAttempts?: number): Promise<XMLHttpRequest> {
        const format     = this.objectData[endpoint].model;
        const pointSize  = this.objectData[endpoint].pointSize;
        const fakeObject = this.objectData[endpoint].fakeObject;
        const animated   = this.objectData[endpoint].animated;

        let promise = undefined;

        switch (endpoint) {
            case RecreaAPI.Endpoints.model:
                promise = this.recreaAPI.getModel(this.projectId, maxAttempts);
                break;
            case RecreaAPI.Endpoints.cameras:
                promise = this.recreaAPI.getCameras(this.projectId, maxAttempts);
                break;
            case RecreaAPI.Endpoints.cloud:
                promise = this.recreaAPI.getCloud(this.projectId, maxAttempts);
                break;
            case RecreaAPI.Endpoints.mesh:
                promise = this.recreaAPI.getMesh(this.projectId, maxAttempts);
                break;
            case RecreaAPI.Endpoints.normalmap:
                promise = this.recreaAPI.getNormalmap(this.projectId, maxAttempts);
                break;
            case RecreaAPI.Endpoints.texture:
                promise = this.recreaAPI.getTexture(this.projectId, maxAttempts);
                break;
        }

        promise.then((event) => {
            this.threeJSHelper.setAnimated(animated);
            this.threeJSHelper.drawData(event.response, new THREE.Vector3(), format, pointSize, fakeObject, event.getResponseHeader('content-type'));
        });

        return promise;
    }

    private init(): void {
        const modelPromise     = this.checkObject(RecreaAPI.Endpoints.model);
        const normalmapPromise = this.checkObject(RecreaAPI.Endpoints.normalmap);
        const texturePromise   = this.checkObject(RecreaAPI.Endpoints.texture);
        const meshPromise      = this.checkObject(RecreaAPI.Endpoints.mesh);
        const cloudPromise     = this.checkObject(RecreaAPI.Endpoints.cloud);
        const camerasPromise   = this.checkObject(RecreaAPI.Endpoints.cameras);

        Promise.all([
            modelPromise,
            normalmapPromise,
            texturePromise,
            meshPromise,
            cloudPromise,
            camerasPromise
        ]).then(
            (res) => {
                let modelPromiseResponse: XMLHttpRequest;
                let normalmapPromiseResponse: XMLHttpRequest;
                let texturePromiseResponse: XMLHttpRequest;
                let meshPromiseResponse: XMLHttpRequest;
                let cloudPromiseResponse: XMLHttpRequest;
                let camerasPromiseResponse: XMLHttpRequest;

                [modelPromiseResponse, normalmapPromiseResponse, texturePromiseResponse, meshPromiseResponse, cloudPromiseResponse, camerasPromiseResponse] = res;

                const modelAvailable     = this.checkResponse(modelPromiseResponse, RecreaAPI.Endpoints.model);
                const normalmapAvailable = this.checkResponse(normalmapPromiseResponse, RecreaAPI.Endpoints.normalmap);
                const textureAvailable   = this.checkResponse(texturePromiseResponse, RecreaAPI.Endpoints.texture);
                const meshAvailable      = this.checkResponse(meshPromiseResponse, RecreaAPI.Endpoints.mesh);
                const cloudAvailable     = this.checkResponse(cloudPromiseResponse, RecreaAPI.Endpoints.cloud);
                const camerasAvailable   = this.checkResponse(camerasPromiseResponse, RecreaAPI.Endpoints.cameras);

                if (modelAvailable) {
                    this.getObject(RecreaAPI.Endpoints.model);
                }
                else if (normalmapAvailable) {
                    this.getObject(RecreaAPI.Endpoints.normalmap);
                }
                else if (textureAvailable) {
                    this.getObject(RecreaAPI.Endpoints.texture);
                }
                else if (meshAvailable) {
                    this.getObject(RecreaAPI.Endpoints.mesh);
                }
                else if (cloudAvailable && this.projectData['cloud/source'] === 'sparse') {
                    this.getObject(RecreaAPI.Endpoints.cloud);
                }
                else if (camerasAvailable) {
                    this.getObject(RecreaAPI.Endpoints.cameras);
                }

                this.initImagesForm();
                this.initCamerasForm();
                this.initCloudForm();
                this.initMeshForm();
                this.initTextureForm();
                this.initNormalMapForm();
                this.initModelForm();
            }
        )
    }

    private initCamerasForm(): void {
        const elem = this.container.find(this.objectData.cameras.itemSelector);

        elem.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.TriggeredEvent) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    accuracy: RecreaManager.getFieldValue(elem.find('[name="parameters[accuracy]"]')),
                    kplimit:  RecreaManager.getFieldValue(elem.find('[name="parameters[kplimit]"]')),
                    tplimit:  RecreaManager.getFieldValue(elem.find('[name="parameters[tplimit]"]')),
                    bbox:     (RecreaManager.getFieldValue(elem.find('[name="parameters[bbox]"]')) === 1) ? 'true' : 'false'
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.cameras, data);
            });

        elem.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.cameras);
            });

        elem.find('button.preview')
            .off('click')
            .on('click', () => {
                this.getObject(RecreaAPI.Endpoints.cameras);
            });

        let matchAccuracy = undefined;
        switch (this.projectData['match/accuracy']) {
            case 'PhotoScan.Accuracy.LowestAccuracy':
                matchAccuracy = '0';
                break;
            case 'PhotoScan.Accuracy.LowAccuracy':
                matchAccuracy = '1';
                break;
            case 'PhotoScan.Accuracy.MediumAccuracy':
                matchAccuracy = '2';
                break;
            case 'PhotoScan.Accuracy.HighAccuracy':
                matchAccuracy = '3';
                break;
            case 'PhotoScan.Accuracy.HighestAccuracy':
                matchAccuracy = '4';
                break;
            default:
                matchAccuracy = this.defaultProjectConfig['match/accuracy'];
        }

        let bbox = undefined;
        if (typeof this.projectData['match/bbox'] !== 'undefined') {
            bbox = (this.projectData['match/bbox'] === 'true');
        }
        else {
            bbox = this.defaultProjectConfig['match/bbox'];
        }

        RecreaManager.setFieldValue(elem.find('[name="parameters[accuracy]"]'), matchAccuracy);
        RecreaManager.setFieldValue(elem.find('[name="parameters[kplimit]"]'), this.projectData['match/kplimit'] || this.defaultProjectConfig['match/kplimit']);
        RecreaManager.setFieldValue(elem.find('[name="parameters[tplimit]"]'), this.projectData['match/tplimit'] || this.defaultProjectConfig['match/tplimit']);
        RecreaManager.setFieldValue(elem.find('[name="parameters[bbox]"]'), bbox);
    }

    private initCloudForm() {
        const bloque = this.container.find(this.objectData.cloud.itemSelector);
        bloque.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.TriggeredEvent) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    source:    RecreaManager.getFieldValue(bloque.find('[name="parameters[source]"]')),
                    format:    this.defaultProjectConfig['cloud/format'],
                    quality:   RecreaManager.getFieldValue(bloque.find('[name="parameters[quality]"]')),
                    filtering: RecreaManager.getFieldValue(bloque.find('[name="parameters[filtering]"]'))
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.cloud, data);
            });

        bloque.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.cloud);
            });

        if (this.projectData['cloud/source'] === 'dense') {
            bloque.find('button.preview').remove();
        }
        else {
            bloque.find('button.preview')
                .off('click')
                .on('click', () => {
                    this.getObject(RecreaAPI.Endpoints.cloud);
                });
        }

        let cloudFiltering = undefined;
        switch (this.projectData['cloud/filtering']) {
            case 'PhotoScan.FilterMode.NoFiltering':
                cloudFiltering = '3';
                break;
            case 'PhotoScan.FilterMode.MildFiltering':
                cloudFiltering = '0';
                break;
            case 'PhotoScan.FilterMode.ModerateFiltering':
                cloudFiltering = '1';
                break;
            case 'PhotoScan.FilterMode.AggressiveFiltering':
                cloudFiltering = '2';
                break;
        }

        let cloudQuality = undefined;
        switch (this.projectData['cloud/quality']) {
            case 'PhotoScan.Quality.LowestQuality':
                cloudQuality = '0';
                break;
            case 'PhotoScan.Quality.LowQuality':
                cloudQuality = '1';
                break;
            case 'PhotoScan.Quality.MediumQuality':
                cloudQuality = '2';
                break;
            case 'PhotoScan.Quality.HighQuality':
                cloudQuality = '3';
                break;
            case 'PhotoScan.Quality.UltraQuality':
                cloudQuality = '4';
                break;
        }

        RecreaManager.setFieldValue(bloque.find('[name="parameters[source]"]'), this.projectData['cloud/source'] || this.defaultProjectConfig['cloud/source']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[quality]"]'), cloudQuality || this.defaultProjectConfig['cloud/quality']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[filtering]"]'), cloudFiltering || this.defaultProjectConfig['cloud/filtering']);
    }

    private initImagesForm(): void {
        // Init vars
        const loadImagesBtn: JQuery           = this.container.find('#load-pictures');
        const loadImagesModal: JQuery         = this.container.find('#picture-uploader');
        const uploadImagesBtn: JQuery         = loadImagesModal.find('#upload-pictures-btn');
        const selectImagesFileField: JQuery   = loadImagesModal.find('#upload-pictures-input');
        const picturesListTable: JQuery       = loadImagesModal.find('#pictures-list');
        const picturesListTableBody: JQuery   = picturesListTable.find('tbody');
        const deleteAllCheckbox: JQuery       = loadImagesModal.find('#delete-all');
        const deleteAllBtn: JQuery            = loadImagesModal.find('.delete-all-pictures');
        const progressBarAllContainer: JQuery = loadImagesModal.find('.progress.all-pictures');
        const progressBarAll: JQuery          = progressBarAllContainer.find('.progress-bar');

        // Store template element
        if (typeof this.picturesListRowTemplate === 'undefined') {
            this.picturesListRowTemplate = picturesListTableBody.find('tr.template').clone().removeClass('d-none').get(0).outerHTML;
            picturesListTable.find('tbody tr.template').remove();
        }

        // Declare functions
        const addImageRow  = (arg: File | string): void => {
            const randomId = 'id_' + Math.random().toString(36).substr(0, 8);
            let rowHTML    = this.picturesListRowTemplate;

            rowHTML = rowHTML.replace(new RegExp('RANDOM_ID', 'g'), randomId);
            if (arg instanceof File) {
                rowHTML = rowHTML.replace(new RegExp('FILE_NAME', 'g'), arg.name);
            }

            const row = jQuery(rowHTML);
            row.removeClass('template');
            picturesListTable.find('tbody').append(row);

            if (arg instanceof File) {
                row.data('file', arg);
                row.find('.file-name').removeClass('d-none');
            }
            else if (typeof arg === 'string') {
                rowShowImage(row, arg);
            }
        };
        const deleteImage  = (img, tr): void => {
            this.requestHelper.delete(this.recreaAPI.getAPIURL(this.projectId, RecreaAPI.Endpoints.photos) + '/' + img)
                .then(
                    () => {
                        tr.remove();
                    }
                );
        };
        const rowShowImage = (row: JQuery, imgFileName: string): void => {
            row.find('.progress').addClass('d-none');
            row.find('.file-name').addClass('d-none');
            row.find('.img-thumbnail')
                .removeClass('d-none')
                .attr('src', this.recreaAPI.getAPIURL(this.projectId, RecreaAPI.Endpoints.thumbnails) + '/' + imgFileName);
            row.find('.btn.delete-picture').removeClass('d-none');
            row.find('.btn.delete-picture').on('click', () => {
                deleteImage(imgFileName, row);
            });
        };
        const uploadImage  = (file: File, row: JQuery): Promise<XMLHttpRequest> => {
            const progressBarContainer: JQuery = row.find('.progress');
            const progressBar: JQuery          = progressBarContainer.find('.progress-bar');

            progressBarContainer.removeClass('d-none');
            progressBar.width(0).html('0%');

            const promise: Promise<XMLHttpRequest> = this.requestHelper.post(
                this.recreaAPI.getAPIURL(this.projectId, RecreaAPI.Endpoints.photos),
                {file: file},
                'json',
                function (event) {
                    if (event.lengthComputable) {
                        const widthPercentage = Math.round((event.loaded / event.total) * 100);
                        progressBar
                            .width(widthPercentage + '%')
                            .html(widthPercentage + '%');
                    }
                });

            promise
                .then((event) => {
                    const data        = event.response;
                    const imgFileName = data.path.substr(data.path.lastIndexOf('/') + 1);
                    rowShowImage(row, imgFileName);
                });

            // tr.find('.uploader').html('<div class="progress progress-striped active" style="margin-bottom: 0;">' +
            //     '<div class="bar" style="width: 0;"></div>' +
            //     '</div>');

            return promise;
        };

        // Events
        loadImagesBtn.on('click', () => {
            loadImagesModal.modal(
                {
                    backdrop: 'static',
                    keyboard: false
                }
            );
        });

        selectImagesFileField.fileupload({
            url:        this.recreaAPI.getAPIURL(this.projectId, RecreaAPI.Endpoints.photos),
            autoUpload: false,
            fileInput:  selectImagesFileField,
            add:        (e: JQueryEventObject, data: JqueryFileUploadAddObject) => {
                for (const file of data.files) {
                    addImageRow(file);
                }
            }
        });

        uploadImagesBtn.on('click', () => {
            const rows                   = picturesListTable.find('tbody tr');
            let completedUploads: number = 0;

            progressBarAllContainer.removeClass('d-none');

            let uploadPromises = new Array<Promise<XMLHttpRequest>>();

            for (const row of rows) {
                const file: File = jQuery(row).data('file');

                const promise = uploadImage(file, jQuery(row));
                promise.then(() => {
                    completedUploads++;
                    const widthPercentage = Math.round((completedUploads / rows.length) * 100);
                    progressBarAll
                        .width(widthPercentage + '%')
                        .html(widthPercentage + '%');
                });

                uploadPromises.push(promise);
            }

            Promise
                .all(uploadPromises)
                .then(() => {
                    progressBarAllContainer.addClass('d-none');
                });
        });

        deleteAllCheckbox.on('change', (e: JQuery.TriggeredEvent) => {
            picturesListTableBody.find('input[type="checkbox"]').prop(
                'checked',
                jQuery(e.target).prop('checked')
            );
        });

        deleteAllBtn.on('click', () => {
            for (const tr of picturesListTableBody.find('tr')) {
                const row = jQuery(tr);
                if (row.find('input[type=checkbox]').prop('checked')) {
                    const imageName = row.find('img').attr('src').substring(row.find('img').attr('src').lastIndexOf('/') + 1);
                    deleteImage(imageName, row);
                }
            }

            deleteAllCheckbox.prop('checked', false);
            deleteAllCheckbox.trigger('change');
        });

        // Load project images
        this.requestHelper.get(this.recreaAPI.getAPIURL(this.projectId, RecreaAPI.Endpoints.photos), 'json')
            .then((event) => {
                const data = event.response;

                for (const photo of data.photos) {
                    addImageRow(photo);
                }
            });
    }

    private initMeshForm(): void {
        const bloque = this.container.find(this.objectData.mesh.itemSelector);
        bloque.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.Event) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    source:  RecreaManager.getFieldValue(bloque.find('[name="parameters[source]"]')),
                    surface: RecreaManager.getFieldValue(bloque.find('[name="parameters[surface]"]')),
                    faces:   RecreaManager.getFieldValue(bloque.find('[name="parameters[faces]"]')),
                    quads:   RecreaManager.getFieldValue(bloque.find('[name="parameters[quads]"]')),
                    holes:   RecreaManager.getFieldValue(bloque.find('[name="parameters[fill_holes]"]')),
                    smooth:  RecreaManager.getFieldValue(bloque.find('[name="parameters[smooth]"]'))
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.mesh, data);
            });

        bloque.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.mesh);
            });

        bloque.find('button.preview')
            .off('click')
            .on('click', () => {
                this.getObject(RecreaAPI.Endpoints.mesh);
            });

        let modelFaces = undefined;
        switch (this.projectData['model/faces']) {
            case 'PhotoScan.FaceCount.LowFaceCount':
                modelFaces = 'low';
                break;
            case 'PhotoScan.FaceCount.MediumFaceCount':
                modelFaces = 'medium';
                break;
            case 'PhotoScan.FaceCount.HighFaceCount':
                modelFaces = 'high';
                break;
        }

        let quads = undefined;
        if (typeof this.projectData['model/quads'] !== 'undefined') {
            quads = (this.projectData['model/quads'] === '1');
        }
        else {
            quads = this.defaultProjectConfig['model/quads'];
        }

        let fillHoles = undefined;
        if (typeof this.projectData['model/fill_holes'] !== 'undefined') {
            fillHoles = (this.projectData['model/fill_holes'] === '1');
        }
        else {
            fillHoles = this.defaultProjectConfig['model/fill_holes'];
        }

        let smooth = undefined;
        if (typeof this.projectData['model/smooth'] !== 'undefined') {
            smooth = (this.projectData['model/smooth'] === '1');
        }
        else {
            smooth = this.defaultProjectConfig['model/smooth'];
        }

        RecreaManager.setFieldValue(bloque.find('[name="parameters[source]"]'), this.projectData['model/source'] || this.defaultProjectConfig['model/source']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[surface]"]'), this.projectData['model/surface'] || this.defaultProjectConfig['model/surface']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[faces]"]'), modelFaces || this.defaultProjectConfig['mesh/faces']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[quads]"]'), quads);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[fill_holes]"]'), fillHoles);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[smooth]"]'), smooth);
    }

    private initModelForm() {
        const bloque = this.container.find(this.objectData.model.itemSelector);
        bloque.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.Event) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    format: this.defaultProjectConfig['3d/format'],
                    embed:  RecreaManager.getFieldValue(bloque.find('[name="parameters[embed]"]')),
                    fwd:    RecreaManager.getFieldValue(bloque.find('[name="parameters[fwd]"]')),
                    up:     RecreaManager.getFieldValue(bloque.find('[name="parameters[up]"]'))
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.model, data);
            });

        bloque.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.model);
            });

        bloque.find('button.preview')
            .off('click')
            .on('click', () => {
                this.getObject(RecreaAPI.Endpoints.model);
            });

        let embed = undefined;
        if (typeof this.projectData['3d/embed'] !== 'undefined') {
            embed = (this.projectData['3d/embed'] === '1');
        }
        else {
            embed = this.defaultProjectConfig['3d/embed'];
        }

        RecreaManager.setFieldValue(bloque.find('[name="parameters[embed]"]'), embed);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[fwd]"]'), this.projectData['3d/fwd'] || this.defaultProjectConfig['3d/fwd']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[up]"]'), this.projectData['3d/up'] || this.defaultProjectConfig['3d/up']);
    }

    private initNormalMapForm() {
        const bloque = this.container.find(this.objectData.normalmap.itemSelector);
        bloque.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.Event) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    size: RecreaManager.getFieldValue(bloque.find('[name="parameters[size]"]'))
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.normalmap, data);
            });

        bloque.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.normalmap);
            });

        bloque.find('button.preview')
            .off('click')
            .on('click', () => {
                this.getObject(RecreaAPI.Endpoints.normalmap);
            });

        RecreaManager.setFieldValue(bloque.find('[name="parameters[size]"]'), this.projectData['normal/size'] || this.defaultProjectConfig['normal/size']);

        // Validación del tamaño
        const sizeInput: HTMLInputElement = this.container.find('[name="parameters[size]"]')[0] as HTMLInputElement;
        let previousValue: number         = parseInt(sizeInput.value);
        bloque.find('[name="parameters[size]"]')
            .off('input')
            .on('input', (e: JQuery.TriggeredEvent) => {
                let value: number = parseInt(sizeInput.value);

                if (previousValue && e.originalEvent instanceof Event && !(e.originalEvent instanceof InputEvent)) {
                    if (previousValue < value) {
                        value = (previousValue * 2);
                    }
                    else if (previousValue > value) {
                        value = (previousValue / 2);
                    }

                    sizeInput.value = value.toString();
                }
                else if (e.originalEvent instanceof InputEvent) {
                    if (RecreaManager.testPowerOf2(value)) {
                        sizeInput.setCustomValidity('');
                        sizeInput.reportValidity();
                    }
                    else {
                        sizeInput.setCustomValidity('El número debe ser una potencia de 2');
                        sizeInput.reportValidity();
                    }
                }

                previousValue = value;
            });
    }

    private initTextureForm(): void {
        const bloque = this.container.find(this.objectData.texture.itemSelector);
        bloque.find('button.generate')
            .off('click')
            .on('click', (e: JQuery.Event) => {
                e.preventDefault();
                e.stopPropagation();

                const data = {
                    size:     RecreaManager.getFieldValue(bloque.find('[name="parameters[size]"]')),
                    blending: RecreaManager.getFieldValue(bloque.find('[name="parameters[blending]"]')),
                    holes:    RecreaManager.getFieldValue(bloque.find('[name="parameters[fill_holes]"]'))
                };
                RecreaManager.removeEmpty(data);

                this.postData(RecreaAPI.Endpoints.texture, data);
            });

        bloque.find('button.download')
            .off('click')
            .on('click', () => {
                this.downloadObject(RecreaAPI.Endpoints.texture);
            });

        bloque.find('button.preview')
            .off('click')
            .on('click', () => {
                this.getObject(RecreaAPI.Endpoints.texture);
            });

        let blending = undefined;
        switch (this.projectData['atlas/blending']) {
            case 'PhotoScan.BlendingMode.MosaicBlending':
                blending = 'mosaic';
                break;
            case 'PhotoScan.BlendingMode.AverageBlending':
                blending = 'average';
                break;
            case 'PhotoScan.BlendingMode.MaxBlending':
                blending = 'max';
                break;
            case 'PhotoScan.BlendingMode.MinBlending':
                blending = 'min';
                break;
            case 'PhotoScan.BlendingMode.DisabledBlending':
                blending = 'disabled';
                break;
        }

        let fillHoles = undefined;
        if (typeof this.projectData['atlas/fill_holes'] !== 'undefined') {
            fillHoles = (this.projectData['atlas/fill_holes'] === '1');
        }
        else {
            fillHoles = this.defaultProjectConfig['atlas/fill_holes'];
        }

        RecreaManager.setFieldValue(bloque.find('[name="parameters[size]"]'), this.projectData['atlas/atlas_height'] || this.defaultProjectConfig['atlas/atlas_height']);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[fill_holes]"]'), fillHoles);
        RecreaManager.setFieldValue(bloque.find('[name="parameters[blending]"]'), blending || this.defaultProjectConfig['atlas/blending']);

        // Validación del tamaño
        const sizeInput: HTMLInputElement = bloque.find('[name="parameters[size]"]')[0] as HTMLInputElement;
        let previousValue: number         = parseInt(sizeInput.value);
        this.container.find(sizeInput)
            .off('input')
            .on('input', (e: JQuery.TriggeredEvent) => {
                let value: number = parseInt(sizeInput.value);

                if (previousValue && e.originalEvent instanceof Event && !(e.originalEvent instanceof InputEvent)) {
                    if (previousValue < value) {
                        value = (previousValue * 2);
                    }
                    else if (previousValue > value) {
                        value = (previousValue / 2);
                    }

                    sizeInput.value = value.toString();
                }
                else if (e.originalEvent instanceof InputEvent) {
                    if (RecreaManager.testPowerOf2(value)) {
                        sizeInput.setCustomValidity('');
                        sizeInput.reportValidity();
                    }
                    else {
                        sizeInput.setCustomValidity('El número debe ser una potencia de 2');
                        sizeInput.reportValidity();
                    }
                }

                previousValue = value;
            });
    }

    private loadProjectData(create?: boolean): Promise<void> {
        return new Promise((resolve) => {
            this.requestHelper.get(this.recreaAPI.getAPIURL(this.projectId), 'json')
                .then((event) => {
                    if (typeof event.response === 'object') {
                        if (event.status === 404 && create === true) {
                            this.recreaAPI.createProject(this.projectId).then((data) => {
                                this.projectId   = data['project/id'];
                                this.projectData = data;
                                resolve();
                            });
                        }
                        else {
                            this.projectId   = event.response['project/id'];
                            this.projectData = event.response;
                            resolve();
                        }
                    }
                });
        });
    }

    private loadingProgressCompleted(endpoint: RecreaAPI.Endpoints, success: boolean, loadingInterval: NodeJS.Timeout) {
        clearInterval(loadingInterval);

        if (success) {
            setTimeout(() => {
                const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);
                const progress    = htmlElement.find('.generation-progress');
                const progressBar = progress.find('.progress-bar');
                const fieldset    = htmlElement.find('fieldset.generation-options');

                this.getObject(endpoint)
                    .then(() => {
                        progress.addClass('d-none');
                        progressBar.width(0).text('0.00%');
                        fieldset.prop('disabled', false);
                        this.enableDownload(endpoint);

                        this.loadProjectData().then(() => {
                            this.init();
                        });
                    });
            }, 1000);
        }
    }

    private loadingProgressShow(queueURL: string, endpoint: RecreaAPI.Endpoints): void {
        const htmlElement = this.container.find(this.objectData[endpoint].itemSelector);
        const progress    = htmlElement.find('.generation-progress');
        const progressBar = progress.find('.progress-bar');
        const fieldset    = htmlElement.find('fieldset.generation-options');

        progress.removeClass('d-none');
        progressBar.width(0).text('0.00%');
        fieldset.prop('disabled', true);
        this.disableDownload(endpoint, true);

        const checkQueue: NodeJS.Timeout = setInterval(() => {
            this.requestHelper.get(queueURL, 'json')
                .then((event: XMLHttpRequest) => {
                    if (event.status === 200) {
                        const response = event.response;

                        if (response && response.progress) {
                            let percent = response.progress;
                            if (percent < 0.01) {
                                percent = 0;
                            }
                            else if (percent > 100) {
                                percent = 100;
                            }

                            progressBar
                                .width(percent + '%')
                                .text(percent.toFixed(2).replace('.', ',') + ' %');
                        }

                        if (event.responseURL !== queueURL) {
                            this.loadingProgressCompleted(endpoint, true, checkQueue);
                        }
                    }
                    else if (event.status === 404) {
                        this.loadingProgressCompleted(endpoint, false, checkQueue);
                    }
                });
        }, 1000);
    }

    private postData(endpoint: RecreaAPI.Endpoints, data: Array<any> | object): Promise<XMLHttpRequest> {
        const promise = this.requestHelper.post(this.recreaAPI.getAPIURL(this.projectId, endpoint), data, '');
        const elem    = this.objectData[endpoint] ? this.container.find(this.objectData[endpoint].itemSelector) : undefined;

        promise.then(
            (event: XMLHttpRequest) => {
                if (event.status === 202) {
                    if (elem) {
                        const responseLocation = event.getResponseHeader('location');
                        const queueURL         = this.recreaAPI.getBaseApiURL() + responseLocation.replace('/api/v1', '');

                        this.loadingProgressShow(queueURL, endpoint);
                    }
                }
            },
            event => {
                console.error('postData', event)
            }
        );

        return promise;
    }

    private threeJSAnimated(animated: AnimationStatus): void {
        if (animated === AnimationStatus.enabled) {
            this.container.find('#play-animation').hide();
            this.container.find('#pause-animation').show();
        }
        else if (animated === AnimationStatus.disabled) {
            this.container.find('#play-animation').show();
            this.container.find('#pause-animation').hide();
        }
        else if (animated === AnimationStatus.forbidden) {
            this.container.find('#play-animation').hide();
            this.container.find('#pause-animation').hide();
        }
    }
}

export default RecreaManager;
