import geoMapViewer from './tags-editor';
import * as Map from 'mapbox-gl';
import StorageHelper from './storage-helper';

type LngLatLike = [number, number];

class GeoMapViewer
{
    private readonly geoMapContainer: JQuery<HTMLElement>;
    private btnPreviewGeoMap: JQuery<HTMLElement>;
    private modal: JQuery<HTMLElement>;
    private accessToken: string;
    private inputLongitude: JQuery<HTMLElement>;
    private inputLatitude: JQuery<HTMLElement>;
    private mapBoxInstance: any;
    private currentMarker: any;
    private StorageWorker: StorageHelper.SessionStorageWorker;
    private storageIdentifier: string = 'lastZoomSelected';
    private modalBtnClose: JQuery<HTMLElement>;

    /**
     * @param elem
     */
    constructor(elem: JQuery<HTMLElement>)
    {
        this.geoMapContainer = elem;
        this.btnPreviewGeoMap = this.geoMapContainer.find('.btn-info');
        this.modal = this.geoMapContainer.find('.modal');
        this.StorageWorker = new StorageHelper.SessionStorageWorker();
        this.modalBtnClose = this.modal.find('#geo-map-modal-btn-close');

        this.setFields();
        this.loadAccessToken();
        this.addEvents();
    }

    /**
     *
     */
    private addEvents(): void
    {
        this.btnPreviewGeoMap.on('click', () => {
            this.modal.modal('show');
            this.initGeoMap('render-geo-map-container');
        });

        this.modalBtnClose.on('click', () => {
            this.modal.modal('hide');
            this.modal.find('#render-geo-map-container').empty();
            this.mapBoxInstance = null;
        });
    }

    /**
     *
     */
    private loadAccessToken(): void
    {
        let element: JQuery<HTMLElement> = this.geoMapContainer.find('#access-token');

        this.accessToken = element.data('token');

        if (!this.accessToken.length) {
            throw 'ACCESS TOKEN NOT FOUND';
        }
        element.remove();
    }

    /**
     *
     * @param containerId
     */
    public initGeoMap(containerId: string): void
    {
        let initialPosition: LngLatLike = this.getInitialPosition();

        let configMapBox = {
            container: containerId,
            style: 'mapbox://styles/mapbox/streets-v11',
            zoom: this.getLastZoomSelected(),
            center: initialPosition
        };

        Object.getOwnPropertyDescriptor(Map, "accessToken").set(this.accessToken);

        this.mapBoxInstance = new Map.Map(configMapBox);

        this.addMarker(initialPosition);

        this.mapBoxInstance.on('click' , (event) => {
            this.setCoordsHandler(event.lngLat.lng, event.lngLat.lat);
        });

        this.mapBoxInstance.on('wheel', (event) => {
            let zoom: number = Math.ceil(this.mapBoxInstance.getZoom());
            this.saveZoomSelected(zoom);
        });
    }

    /**
     *
     */
    private getLastZoomSelected(): number
    {
        let storageValue: string | null = this.StorageWorker.get(this.storageIdentifier);

        if (storageValue) {
            return parseInt(storageValue);
        }
        return 18;
    }

    /**
     * @param zoom
     */
    private saveZoomSelected(zoom: number): void
    {
        this.StorageWorker.add(this.storageIdentifier, zoom.toString());
    }

    /**
     *
     */
    private getMuseumCoordinates(): LngLatLike | null
    {
        const span: JQuery = this.geoMapContainer.find('#position-config-museum');

        if(!span) {
            return null;
        }
        const lat: number|null = span.data('lat');
        const lng: number|null = span.data('lng');

        if (!lat || !lng) {
            return null;
        }

        return [lng, lat];
    }

    /**
     *
     * @param longitude
     * @param latitude
     */
    private setCoordsHandler(longitude: number, latitude: number): void
    {
        if (typeof this.currentMarker == 'object') {
            this.currentMarker.remove();
        }
        this.addMarker([longitude, latitude]);
        this.inputLongitude.val(longitude.toString());
        this.inputLatitude.val(latitude.toString());
    }

    /**
     *
     * @param coords
     */
    private addMarker(coords: LngLatLike): void
    {
        let markOptions: any = {
           color: '#007bff'
        };

        this.currentMarker = new Map.Marker(markOptions).setLngLat(coords);
        this.currentMarker.addTo(this.mapBoxInstance);
    }

    /**
     *
     */
    private getInitialPosition(): LngLatLike
    {
        const existingCoords: LngLatLike | null = this.getExistingCoords();

        if (existingCoords) {
           return existingCoords;
        }

        const museumCoords: LngLatLike | null = this.getMuseumCoordinates();

        if (museumCoords) {
            return museumCoords;
        }
        return [-3.7025600, 40.4165000];
    }

    /**
     *
     */
    private getExistingCoords(): LngLatLike | null
    {
        let lat: string = this.inputLatitude.val().toString(),
            lng: string = this.inputLongitude.val().toString();

        if (lat && lng) {
            return [parseFloat(lng), parseFloat(lat)];
        }
        return null;
    }

    /**
     * Define pattern Id from node element by suffix generated by symfony form
     */
    private setFields(): void
    {
        this.inputLatitude = jQuery('[id$="_lat"]');
        this.inputLongitude = jQuery('[id$="_lng"]');
    }
}

export default GeoMapViewer;

declare global {
    interface JQuery {
        geoMapViewer()
    }
}

(function ($) {

    $.fn.geoMapViewer = function() {
        return this.each((index: number, elem: HTMLElement) => {

            let geoMapViewer = jQuery(elem).data('geoMapViewer');
            if (typeof geoMapViewer === 'undefined') {
                geoMapViewer = new GeoMapViewer(jQuery(elem));
                jQuery(elem).data('geoMapViewer', geoMapViewer);
            }

            return geoMapViewer;
        });
    }
})(jQuery);
