import {OptGroupData, OptionData, SearchOptions} from "select2";

/**
 *
 * @param value
 */
const toSlug = (value: string | null): string | null => {

    if (!value) return null;

    return value
        .toLowerCase()
        .replace('á', 'a')
        .replace('é', 'e')
        .replace('í', 'i')
        .replace('ó', 'o')
        .replace('ú', 'u');
};

/**
 *
 * @param params
 * @param data
 */
const select2Matcher = (params: SearchOptions, data: OptGroupData | OptionData): OptGroupData | OptionData => {

    const element: JQuery = jQuery(data.element);
    if (jQuery.trim(params.term) === '') {
        return data;
    }

    if (!data.text) {
        return null;
    }

    if (toSlug(data.text).indexOf(toSlug(params.term)) > -1) {
        return data;
    }

    if (element.data('tags').includes(params.term)) {
        return data;
    }

    return null;
};

export default select2Matcher;