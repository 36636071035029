class CarouselImageManager {
    constructor(elem) {
        const $elem             = $(elem);
        const $btnAddImage      = $elem.find('.btn.add-image').parent();
        const newImagePrototype = $elem.find('.new-image-prototype').data('prototype');
        let index               = $elem.find('.image').length;

        $btnAddImage.on('click', function () {
            const newImageHTML = jQuery.trim(newImagePrototype.trim().replace(/__name__/g, index)); // Clean prototype
            const $newImage    = jQuery(newImageHTML); // Generate new element
            index++; // Update images count
            let dataModified = false; // Flag to check if element has been modified
            $btnAddImage.before($newImage); // Add element before the button
            $newImage.find('.btn[data-toggle="modal"]').trigger('click'); // Open modal to edit the image data
            $newImage.find('.modal').on('hide.bs.modal', function () {
                // If modal is closed and no data has been modified, remove element
                if (dataModified === false) {
                    $newImage.remove();
                    index--; // Update images count
                }
            });
            $newImage.find('input, select, textarea').on('change', function () {
                // When a field is updated, set the element as modified
                dataModified = true;
            });
            $newImage.find('.advanced-file-manager').advancedUploader(); // Init file uploads

            $newImage.carouselImage(); // Init image controlº
        });

        $elem.find('.image').carouselImage(); // Init images control
    }
}

interface JQuery {
    carouselImageManager();
}

(function ($) {
    $.fn.carouselImageManager = function () {
        return this.each((index: number, elem: HTMLElement) => {
            new CarouselImageManager(jQuery(elem));
        });
    }
})(jQuery);
