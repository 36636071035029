export default class ConfirmMessage {

	private modalConfirm: JQuery;
	private path: string;
	private index: number;

	private continueBtn: string;
	private cancelBtn: string;
	private message: string;
	private titleElementLang: string;

	constructor(item: JQuery<HTMLElement>, index: number) 
	{
		if(item.data('confirm')){

			this.index = index;

			item.on('click', (e: JQuery.TriggeredEvent) => {

				e.preventDefault();

				let existsModal = jQuery('#modalConfirm' + this.index);

				if (!existsModal.length) {
					this.path = item.attr('href');

					this.message = item.data('message');
					this.cancelBtn = item.data('cancelbtn');
					this.continueBtn = item.data('continuebtn');
					this.titleElementLang = item.data('title');

					this.setModal();
					this.configModal();		

				} else {
					this.modalConfirm = existsModal;
				}
		
				this.initModal();
			});			
		}
	}

	private setModal()
	{
		let modal: JQuery = jQuery('<div>', {
			class: "modal fade",
			id: 'modalConfirm' + this.index,
			role: 'dialog'
		});

		modal.append('<div class="modal-dialog" role="document"><div class="modal-content"><div class="modal-header"></div><div class="modal-body"></div><div class="modal-footer"></div></div></div>');

		this.modalConfirm = modal;
	}

	private configModal() 
	{
		let elementId = 'confirmAction' + this.index;

		this.modalConfirm.find('.modal-footer').append('<button type="button" class="btn btn-secondary" data-dismiss="modal">' + this.cancelBtn + '</button>');
		this.modalConfirm.find('.modal-footer').append('<button type="button" class="btn btn-primary">' + this.continueBtn + '</button>');

		this.modalConfirm.find('.btn-primary').attr('id', elementId);
		this.modalConfirm.find('.modal-header').append('<h5>' + this.titleElementLang + '</h5>');
		this.modalConfirm.find('.modal-body').append('<p>' + this.message + '</p>');

		this.modalConfirm.find('#' + elementId).on('click', () => {
			window.location.href = this.path;
		});
		this.modalConfirm.find('[data-dismiss="modal"]').on('click', () => {
			this.modalConfirm.modal('hide');
		});
	}

	private initModal() 
	{ 
		jQuery('.table-responsive').append(this.modalConfirm);

		this.modalConfirm.modal({
			backdrop: true,
			keyboard: true,
			focus: false,
			show: true
		});
	}
}

declare global {
    interface JQuery {
        confirmMessage()
    }
}

(function ($) {
    $.fn.confirmMessage = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let confirmMessage = jQuery(elem).data('confirmMessage');
            if (typeof confirmMessage === 'undefined') {
                confirmMessage = new ConfirmMessage(jQuery(elem), index);
                jQuery(elem).data('confirmMessage', confirmMessage);
            }

            return confirmMessage;
        });
    }
})(jQuery);