import {LoadingData, OptGroupData, OptionData, SearchOptions} from "select2";

declare global {
    interface JQuery {
        customizedSelect2()
    }
}

(function ($) {
    $.fn.customizedSelect2 = function () {
        this.each((index: number, elem: HTMLElement) => {
            const chosenField = jQuery(elem);

            chosenField.select2({
                // @ts-ignore
                templateSelection: (tag: LoadingData, container: JQuery<HTMLLIElement>) => {
                    const option: JQuery<HTMLOptionElement> = jQuery(tag.element);

                    if (option.attr('locked')) {
                        container.addClass('locked-tag');
                        // @ts-ignore
                        tag.locked = true;
                    }

                    const span = jQuery('<span></span>');
                    span.text(tag.text);
                    span.attr('data-id', tag.id);

                    return span;
                },
                matcher:           (params: SearchOptions, data: OptGroupData | OptionData): OptGroupData | OptionData => {
                    const element = jQuery(data.element);

                    if (data["locked"] === true) {
                        return null;
                    }

                    if (jQuery.trim(params.term) === '') {
                        return data;
                    }

                    if (typeof data.text === 'undefined') {
                        return null;
                    }

                    if (data.text.replace('(' + element.data('type') + ')', '').indexOf(params.term) > -1) {
                        return data;
                    }

                    if (element.data('tags').includes(params.term)) {
                        return data;
                    }

                    return null;
                }
            });

            if (chosenField.data('sortable') && chosenField.data('sortable') === true) {
                chosenField.data('select2').$container.find('ul.select2-selection__rendered')
                    .sortable({
                        containment: 'parent',
                        stop:        function (event, ui) {
                            const ul     = chosenField.data('select2').$container.find('ul.select2-selection__rendered');
                            const select = chosenField;

                            jQuery(ul.find('li.select2-selection__choice').get().reverse())
                                .each((index: number, elem: HTMLLIElement) => {
                                    const li = jQuery(elem);
                                    const id = li.find('span[data-id]').data('id');

                                    select.prepend(select.find('option[value="' + id + '"]'));
                                });
                        }
                    });
            }

            chosenField.on('select2:unselecting', (e: JQuery.TriggeredEvent) => {
                // @ts-ignore
                if ($(e.params.args.data.element).attr('locked')) {
                    e.preventDefault();
                }
            });
        });
    }
})(jQuery);
