class SubtitlesParser
{
    private readonly regexSTRFilePattern: any = /([0-9]{1,}\n[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}\s?-->\s?[0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3}\n[^\n]+\n?[^\n]+)/g;
    private readonly regexTimeFormat: any = /([0-9]{2}:[0-9]{2}:[0-9]{2},[0-9]{3})/g;

    /**
     * @param textPlain
     */
    public validateSRT(textPlain: string): boolean
    {
        return this.regexSTRFilePattern.test(textPlain);
    }

    /**
     *
     * @param textPlain
     */
    public toArray(textPlain: string): Array<{ duration: number, position: number, subtitle: string }>
    {
        let matches: Array<string>,
            duration: number,
            position: number,
            subtitle: string,
            lines: Array<string>,
            subtitlesParsed: Array<{ duration: number, position: number, subtitle: string }>;

        subtitlesParsed = [];
        matches = textPlain.match(this.regexSTRFilePattern);

        for (let match of matches) {

            lines = match.split("\n");

            position = parseInt(lines[0]);
            subtitle = lines[2];
            duration = this.getNumberSeconds(lines[1]);

            if (lines.length > 3) {
                subtitle += "\n";
                subtitle += lines[3];
            }
            subtitlesParsed.push({
                position: position,
                duration: duration,
                subtitle: subtitle
            });
        }
        return subtitlesParsed;
    }

    /**
     *
     * @param format
     */
    private getNumberSeconds(format: string): number
    {
        let matches: Array<string> = format.match(this.regexTimeFormat);

        if (matches.length !== 2) {
            throw 'ERROR PATTERN TIME FORMAT INVALID';
        }
        return this.getSecondsByTimeFormat(matches[1]) - this.getSecondsByTimeFormat(matches[0]);
    }

    /**
     *
     * @param format
     */
    private getSecondsByTimeFormat(format: string): number
    {
        let timeFormat: string = format.split(',')[0],
            hours: number = parseInt(timeFormat.split(':')[0]),
            minutes: number = parseInt(timeFormat.split(':')[1]),
            seconds: number = parseInt(timeFormat.split(':')[2]);

        if (hours) {
            minutes += hours * 60;
        }
        if (minutes) {
            seconds += minutes * 60;
        }
        return seconds;
    }

    /**
     *
     * @param subtitles
     */
    public toSRTFormat(subtitles: Array<{ duration: number, position: number, subtitle: string }>): string
    {
        let plainText: string = '',
            countedSeconds: number = 0;

        for (let i: number = 0; i < subtitles.length; i++) {

            plainText += subtitles[i].position;
            plainText += "\n";
            plainText += this.secondToTimeFormat(countedSeconds) + ' --> ' + this.secondToTimeFormat(countedSeconds + subtitles[i].duration);
            plainText += "\n";
            plainText += subtitles[i].subtitle;
            plainText += "\n\n";

            countedSeconds = countedSeconds + subtitles[i].duration;
        }
        return plainText;
    }

    /**
     *
     * @param seconds
     */
    private secondToTimeFormat(seconds: number): string
    {
        let  secondsTime: number,
            secondsRawTime: string,
            minutesTime: number,
            minutesRawTime: string,
            hoursTime: number,
            hoursRawTime: string,
            millisecondsRawTime: string;

        secondsTime = seconds % 60;
        minutesTime = Math.floor(seconds / 60);
        hoursTime   = Math.floor(minutesTime / 60);
        minutesTime = minutesTime % 60;

        hoursRawTime            = hoursTime < 10    ? `0${hoursTime}`   : hoursTime.toString();
        minutesRawTime          = minutesTime < 10  ? `0${minutesTime}`  : minutesTime.toString();
        secondsRawTime          = secondsTime < 10  ? `0${secondsTime}`  : secondsTime.toString();
        millisecondsRawTime     = '000';

        return `${hoursRawTime}:${minutesRawTime}:${secondsRawTime},${millisecondsRawTime}`;
    }

    /**
     *
     */
    public getSRTFilename(): string
    {
        let filename: string = 'subtitles_',
            date: Date = new Date();

        filename += date.getFullYear();
        filename += date.getMonth();
        filename += date.getDay();
        filename += date.getHours();
        filename += date.getMinutes();
        filename += date.getSeconds();
        filename += '.srt';

        return filename;
    }
}

export default SubtitlesParser;