class QuizAnswerManager {

    private tableAnswersHTML: JQuery<HTMLElement>;
    private nodePrototype: string;

    public constructor(elem: JQuery<HTMLElement>) {

        this.tableAnswersHTML = elem.find('.table tbody');
        this.nodePrototype = elem.find('.new-answer-prototype').data('prototype');

        this.registerEventListenerOnSortRows();
        this.registerEventListenerOnDelete();
        this.registerEventListenerOnEdit();
        this.registerEventListenerNewAnswer(elem.find('.btn.add-answer'));
    }

    private registerEventListenerNewAnswer(btnAddAnswer: JQuery<HTMLElement>): void {

        btnAddAnswer.on('click', () => {

            let dataModified = false;

            const newAnswerHTML = jQuery.trim(this.nodePrototype.trim().replace(/__name__/g, this.tableAnswersHTML.find('tr').length.toString()));
            const newAnswer     = jQuery(newAnswerHTML);

            this.tableAnswersHTML.append(newAnswer);

            newAnswer.find('.btn[data-toggle="modal"]').trigger('click'); // Open modal to edit the answer data
            newAnswer.find('.advanced-file-manager').advancedUploader();

            newAnswer.find('.modal').on('hide.bs.modal', () => {
                // If modal is closed and no data has been modified, remove element
                if (dataModified === false) {
                    newAnswer.remove();
                }
            });

            newAnswer.find('input[type="text"]').on('change', (ev: Event) => {
                // When a field is updated, set the element as modified
                dataModified = true;

                let valueText = jQuery(ev.currentTarget).val();

                newAnswer.find('td p').html(valueText.toString());
            });

            newAnswer.find('input[type="checkbox"]').on('change', (ev: Event) => {
                newAnswer.find('td p').removeClass();
                if (jQuery(ev.currentTarget).is(':checked')) {
                    newAnswer.find('td p').addClass('text-success');
                }
                else {
                    newAnswer.find('td p').addClass('text-danger');
                }
            });

            newAnswer.find('.manage .delete').on('click', (event: Event) => {
                this.handleDeleteEvent(event);
            });

            newAnswer.find('.manage .sort-answer').on('click', (event: Event) => {
                this.handleOnSortRows(event);
            });

            this.fixPositionValues();
        });
    }

    private registerEventListenerOnSortRows(): void {
        this.tableAnswersHTML.find('.manage .sort-answer').on('click', (event: Event) => {
            this.handleOnSortRows(event);
        });
    }

    private registerEventListenerOnDelete(): void {
        this.tableAnswersHTML.find('.manage .delete').on('click', (event: Event) => {
            this.handleDeleteEvent(event);
        });
    }

    private registerEventListenerOnEdit(): void {
        for (var i = 0; i < this.tableAnswersHTML.find('tr').length; i++) {

            let elem = this.tableAnswersHTML.find('#quiz_answers_' + i + '_grill');

            elem.find('input[type="text"]').on('change', (event: Event) => {
                let valueText = jQuery(event.currentTarget).val();

                elem.find('td p').html(valueText.toString());
            });

            elem.find('#quiz_answers_' + i + '_correct').on('change', (event: Event) => {
                elem.find('td p').removeClass();
                if (jQuery(event.currentTarget).is(':checked')) {
                    elem.find('td p').addClass('text-success');
                }
                else {
                    elem.find('td p').addClass('text-danger');
                }
            });
        }
    }

    private fixPositionValues(): void {
        let rows = this.tableAnswersHTML.find('tr');
        for (let i = 0; i < rows.length; i++) {
            jQuery(rows[i]).find('.position_value').val(i);
        }
    }

    private handleDeleteEvent(event: Event): void {
        let target = jQuery(event.currentTarget);

        this.tableAnswersHTML.find('#' + target.data('delete') + '_grill').remove();
        this.fixPositionValues();
    }

    private handleOnSortRows(event: Event): void {
        let direction = jQuery(event.currentTarget).data('direction');
        let row = jQuery(event.currentTarget).parent().parent().parent();
        if (direction === 'up') {
            // @ts-ignore
            row.insertBefore(row.prev())
        }
        if (direction === 'down') {
            // @ts-ignore
            row.insertAfter(row.next())
        }
        this.fixPositionValues();
    }
}

interface JQuery {
    quizAnswerManager();
}

(function ($) {
    $.fn.quizAnswerManager = function () {
        return this.each((index: number, elem: HTMLElement) => {
            new QuizAnswerManager(jQuery(elem));
        });
    }
})(jQuery);
