class EventSessionEditor {

    public constructor(
        private element: JQuery<HTMLElement>
    ) {
        this.init();
    }

    private init(): void {
        this.element.find('#add-event-session-btn').on('click', () => {
           this.addEventSession();
        });
        this.element.find('.remove-event-session').on('click', (event: Event) => {
            jQuery(event.target).parent().parent().remove();
        });
    }

    private addEventSession(): void {

        let prototype: JQuery<HTMLElement> = this.element.find('.new-event-session-prototype').data('prototype');

        this.element.find('#table-body-collection').append(
            prototype.toString().replace(/__name__/g, this.getIndex().toString())
        );
    }

    private getIndex(): number {
        return this.element.find('#table-body-collection').find('tr').length;
    }
}

interface JQuery {
    eventSessionEditor();
}

(function ($) {

    $.fn.eventSessionEditor = function() {
        return this.each((index: number, elem: HTMLElement) => {
            let eventSessionEditor = jQuery(elem).data('eventSessionEditor');
            if (typeof eventSessionEditor === 'undefined') {
                eventSessionEditor = new EventSessionEditor(jQuery(elem));
                jQuery(elem).data('eventSessionEditor', eventSessionEditor);
            }
            return eventSessionEditor;
        });
    }
})(jQuery);
