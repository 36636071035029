import RecreaManager from './recrea-manager';
import RecreaManagerCreate from './recrea-manager-create';
import Scene360Manager from "./scene360-manager";
import ColorPicker from "./colorpicker";
import TriggeredEvent = JQuery.TriggeredEvent;

require('../css/app.scss');

const $ = require('jquery');
// JS is equivalent to the normal "bootstrap" package
// no need to set this to a variable, just require it
require('bootstrap');

// or you can include specific pieces
// require('bootstrap/js/dist/tooltip');
// require('bootstrap/js/dist/popover');

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('select2/dist/js/select2.full');
require('summernote/dist/summernote');
require('summernote/dist/summernote-bs4');
require('summernote/lang/summernote-es-ES');
require('codemirror/mode/xml/xml');
require('blueimp-file-upload');
require('bootstrap-star-rating');
require('bootstrap-star-rating/themes/krajee-fas/theme');

require('./jquery-chosen-sortable');
require('./advanced-uploader');
require('./locution-manager');
require('./carousel-image');
require('./carousel-image-manager');
require('./quiz-answer-manager');
require('./request-helper');
require('./select2');
require('./tree-editor');
require('./items-tree-editor');
require('./tags-editor');
require('./confirm-message');
require('./subtitles-editor');
require('./restore-tab-selected');
require('./geo-map-viewer');
require('./send-notification');
require('./notification-form');
require('./event-session-editor');
require('./schedule-editor');

$.fn.select2.defaults.set("theme", "bootstrap4");

jQuery(function () {

    // Enable popover
    jQuery('[data-toggle="popover"]').popover();

    // Enable tooltip
    jQuery('[data-toggle="tooltip"]').tooltip();

    // Enable select2
    jQuery('select[data-toggle="chosen"]').customizedSelect2();

    // Enable summernote
    jQuery('.summernote').each(function (index, item) {
        const $item = jQuery(item);
        $item.summernote({
            minHeight: 150,
            lang:      $item.data('lang').replace('_', '-'),
            callbacks: {
                onInit:   function (e) {
                    if (e.note.hasClass('form-control')) {
                        e.editor.addClass('form-control');
                    }
                    if (e.note.hasClass('is-invalid')) {
                        e.editor.addClass('is-invalid');
                    }
                },
                onChange: function (contents) {
                    if ($item.summernote('isEmpty') && contents) {
                        $item.summernote('code', '');
                    }
                },
                onPaste: (event) => {
                    let text: any = (event.originalEvent || event).clipboardData.getData('Text');

                    event.preventDefault();
                    $item.summernote('insertText', text);
                }
            }
        })
    });

    // Change tab on form field not valid
    jQuery("form .tabs-collection-widget input").on("invalid", function () {
        jQuery('.tabs-collection-widget a[href="#' + jQuery(this).closest('.tab-pane').attr('id') + '"]').tab('show');
    });

    // Enable uploader
    jQuery('.advanced-file-manager').advancedUploader();

    // Accessibility form - generate locution
    jQuery('.row.locution').locutionManager();

    // Recrea manager
    new RecreaManager(jQuery('#recrea-manager'));
    new RecreaManagerCreate(jQuery('.row.recrea-manager-create'));

    // Scene360 manager
    new Scene360Manager(jQuery('.scene360-manager'));

    // Carousel image manager
    jQuery('form .content_editor#carousel .image-manager').carouselImageManager();

    // Quiz answer manager
    jQuery('form .content_editor#quiz .answer-manager').quizAnswerManager();

    // Color picker
    jQuery('[data-toggle="color-picker"]').each((index: number, elem: HTMLElement) => {
        new ColorPicker(elem);
    });

    // Tree editor
    jQuery('[data-toggle="tree-editor"]').treeEditor();

    // Tour tree editor
    jQuery('[data-toggle="tour-tree-editor"]').itemsTreeEditor('tour');

    // Section tree editor
    jQuery('[data-toggle="section-tree-editor"]').itemsTreeEditor('section');

    // Tags editor
    jQuery('[data-toggle="tags-editor"]').tagsEditor();

    // Subtitle editor
    jQuery('.subtitle-context').subtitlesEditor();

    // Confirm message - contents
    jQuery('.contents-table a').confirmMessage();

    // Confirm message - tours
    jQuery('.tours-table a').confirmMessage();

    // Confirm message - Notifications
    jQuery('.notifications-table a').confirmMessage();

    // Restore selection of the lang tag when refresh page
    jQuery('form').restoreTabSelected();

    // Button geo map viewer
    jQuery('#geo-map-viewer').geoMapViewer();

    // Display modal with configuration to sent
    jQuery('.notification-row').sendNotification();

    // Initialize items selector
    jQuery('form[class="notification_editor"]').notificationForm();

    // Initialize event session editor
    jQuery('#event-session-collection').eventSessionEditor();

    // Schedule editor
    jQuery('#schedule-editor').scheduleEditor();

    // Star rating
    $('[data-toggle="rating"]').rating({
        containerClass:     'form-control p-0 border-0',
        hoverOnClear:       false,
        theme:              'krajee-fas',
        showCaption:        false,
        showCaptionAsTitle: false
    });
});
