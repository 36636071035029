
class CarouselImage {

    private elem: JQuery<HTMLElement>;
    private image: JQuery<HTMLElement>;
    private btnDelete: JQuery<HTMLElement>;
    private btnSortNext: JQuery<HTMLElement>;
    private btnSortBack: JQuery<HTMLElement>;
    private hiddenFieldPositionValue: JQuery<HTMLElement>;

    constructor(elem)
    {
        this.elem         = $(elem);
        this.image        = this.elem.find('.card-img-top img');
        this.btnDelete    = this.elem.find('.btn-group.manage .delete');
        this.btnSortNext  = this.elem.find('.btn-group.manage .sort-next');
        this.btnSortBack  = this.elem.find('.btn-group.manage .sort-back');

        this.hiddenFieldPositionValue = this.elem.find('#carousel_images_' + this.elem.index() + '_position');

        this.updateOtherImagePositionFields();
        this.addEvents();
    }

    /**
     *
     */
    private addEvents(): void
    {
        this.btnDelete.on('click', () => {
            this.elem.remove();
        });

        this.btnSortNext.on('click', () => {

            if ( (this.elem.index() + 1) < this.getNumberImages() ) {
                this.elem.insertAfter(this.elem.next());
                this.updateOtherImagePositionFields();
            }
        });

        this.btnSortBack.on('click', () => {

            if (this.elem.index()> 0) {
                this.elem.insertBefore(this.elem.prev());
                this.updateOtherImagePositionFields();
            }
        });

        this.elem.find('.main_language .advanced-file-manager').on(
            'fileUploaded',
            (e: JQuery.Event, filePath: string) => { // Trigger on uploaded file
                this.image.attr('src', '/' + filePath);
            }
        );
    }

    /**
     *
     * @param index
     */
    private updateOtherImagePositionFields(): void
    {
        let images = this.elem.parent().children('.image');

        for (let i: number = 0; i < images.length; i++) {

            let image = jQuery(images[i]),
                index = (i + 1).toString();

            image.find('.position_value').val(index);
        }
    }

    /**
     *
     */
    private getNumberImages(): number
    {
        return this.elem.parent().children().length - 1;
    }
}

interface JQuery {
    carouselImage();
}

(function ($) {
    $.fn.carouselImage = function () {
        return this.each((index: number, elem: HTMLElement) => {
            new CarouselImage(jQuery(elem));
        });
    }
})(jQuery);
