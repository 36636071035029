import Translator from "./transator";

class ScheduleEditor {

    private weekDaysFields: string[];

    public constructor(
        private readonly element: JQuery<HTMLElement>
    ) {
        this.init();
    }

    public init(): void {

        this.weekDaysFields = [
            Translator.trans('schedule.sunday', {}, 'messages'),
            Translator.trans('schedule.monday', {}, 'messages'),
            Translator.trans('schedule.tuesday', {}, 'messages'),
            Translator.trans('schedule.wednesday', {}, 'messages'),
            Translator.trans('schedule.thursday', {}, 'messages'),
            Translator.trans('schedule.friday', {}, 'messages'),
            Translator.trans('schedule.saturday', {}, 'messages')
        ];
        this.element.find('#btn-open-modal').on('click', () => {
            this.element.find('.modal').modal('show');
        });
        this.element.find('#btn-close-modal').on('click', () => {
            this.element.find('.modal').modal('hide');
        });
        this.element.find('#btn-interval-create').on('click', () => {
            this.createDayTimeInterval();
        });
        this.element.find('.date-time-interval-row').each((index: number, element: HTMLElement) => {
            jQuery(element).find('.delete-day-time-interval').on('click', () => {
                jQuery(element).remove();
            });
        });

    }

    private createDayTimeInterval(): void {

        const day = this.element.find('#index-day-create').val();
        const startTimeHour = this.element.find('#from-time-create').find('.hour').val();
        const startTimeMinute = this.element.find('#from-time-create').find('.minutes').val();
        const endTimeHour = this.element.find('#to-time-create').find('.hour').val();
        const endTimeMinute = this.element.find('#to-time-create').find('.minutes').val();

        let found = false;
        this.element.find('.schedule-day').each((index: number, element: HTMLElement) => {

            if (day === jQuery(element).find('.day').val()) {
                found = true;

                let prototypeDayTimeInterval: JQuery<HTMLElement> = jQuery(element)
                    .find('#new-day-time-interval-prototype')
                    .data('prototype')
                    .replace(/__ti_name__/g, jQuery(element).find('tr').length.toString());

                prototypeDayTimeInterval = jQuery(prototypeDayTimeInterval);
                this.addPrototypeDayTimeInterval(
                    jQuery(element),
                    jQuery(prototypeDayTimeInterval),
                    startTimeHour as string,
                    startTimeMinute as string,
                    endTimeHour as string,
                    endTimeMinute as string
                );
            }
        });
        if (!found) {

            let prototypeDay: JQuery<HTMLElement> = this.element
                .find('#new-day-prototype')
                .data('prototype')
                .replace(/__d_name__/g, this.element.find('.schedule-day').length.toString());

            prototypeDay = jQuery(prototypeDay);
            prototypeDay.find('h5').text(this.weekDaysFields[day as number]);
            prototypeDay.find('.day').val(day);

            let prototypeDayTimeInterval: JQuery<HTMLElement> = prototypeDay
                .find('#new-day-time-interval-prototype')
                .data('prototype')
                .replace(/__ti_name__/g, '0');

            this.addPrototypeDayTimeInterval(
                prototypeDay,
                jQuery(prototypeDayTimeInterval),
                startTimeHour as string,
                startTimeMinute as string,
                endTimeHour as string,
                endTimeMinute as string
            );
            this.element.find('#days').append(prototypeDay);
        }
    }

    private addPrototypeDayTimeInterval(
        destination: JQuery<HTMLElement>,
        prototype: JQuery<HTMLElement>,
        startTimeHour: string,
        startTimeMinute: string,
        endTimeHour: string,
        endTimeMinute: string
    ): void {

        prototype.find('.start-time-interval').find('[id$=hour]').find('option[value="'+startTimeHour+'"]').attr('selected', 'selected');
        prototype.find('.start-time-interval').find('[id$=minute]').find('option[value="'+startTimeMinute+'"]').attr('selected', 'selected');
        prototype.find('.end-time-interval').find('[id$=hour]').find('option[value="'+endTimeHour+'"]').attr('selected', 'selected');
        prototype.find('.end-time-interval').find('[id$=minute]').find('option[value="'+endTimeMinute+'"]').attr('selected', 'selected');

        prototype.find('.delete-day-time-interval').on('click', () => {
            prototype.remove();
        });
        destination.find('tbody').append(prototype);
    }
}
declare global {
    interface JQuery {
        scheduleEditor();
    }
}

(function ($) {

    $.fn.scheduleEditor = function() {
        return this.each((index: number, elem: HTMLElement) => {
            let scheduleEditor = jQuery(elem).data('scheduleEditor');
            if (typeof scheduleEditor === 'undefined') {
                scheduleEditor = new ScheduleEditor(jQuery(elem));
                jQuery(elem).data('scheduleEditor', scheduleEditor);
            }
            return scheduleEditor;
        });
    }
})(jQuery);
