import * as THREE from "three";
import GLTFLoader from 'three-gltf-loader';
import Detector from './detector';
import OrbitControls from 'three-orbit-controls';

const OrbitControls = require('three-orbit-controls')(THREE);
require('three-ply-loader')(THREE);

type Rotation = {
    w: number,
    y: number,
    x: number,
    z: number
};
type Translation = {
    y: number,
    x: number,
    z: number
};
type Camera = {
    id: string,
    quality: number,
    rotation: Rotation,
    scale: number,
    translation: Translation
};
type CamerasJSON = {
    cameras: Camera[],
    mean_kp_size: number,
    overlap: number,
    rmse: number
}
type objectLoadedCallback = (object: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => void;
type Size = {
    height: number,
    width: number
};
type animatedHandler = { (animated: AnimationStatus): void };

export enum AnimationStatus {
    enabled   = 1,
    disabled  = 0,
    forbidden = -1
}

class ThreeJSHelper {
    private readonly allowMultipleObjects: boolean;
    private animated: AnimationStatus             = AnimationStatus.enabled;
    private animatedHandler: animatedHandler;
    private readonly animationSpeed               = 0.01;
    private readonly aspectRatio                  = 16 / 9; // Width/height
    private readonly axesHelper: THREE.AxesHelper = new THREE.AxesHelper(5);
    private background: THREE.Mesh;
    private readonly backgroundColor: string      = '#e9ecef';
    private camera: THREE.PerspectiveCamera;
    private cameraTarget: THREE.Vector3;
    private readonly containerImage: HTMLElement;
    private readonly containerModel: HTMLElement;
    private controlsOrbit: OrbitControls;
    private distanceCameraToObject: number;
    private fakeObject: THREE.Mesh;
    private previousAnimated: AnimationStatus;
    private renderedImage: HTMLImageElement;
    private renderedObject: THREE.Object3D;
    private renderer: THREE.WebGLRenderer;
    private scene: THREE.Scene;

    constructor(containerModel: HTMLElement, containerImage: HTMLElement, allowMultipleObjects) {
        this.allowMultipleObjects = allowMultipleObjects;
        this.containerImage       = containerImage;
        this.containerModel       = containerModel;

        if (Detector.webgl()) {
            Detector.addGetWebGLMessage();
        }

        jQuery(this.containerImage).empty().append($('<canvas>'));
    }

    private static drawJSON(data: CamerasJSON, onObjectLoaded: objectLoadedCallback): void {
        const geometry = new THREE.Geometry();

        if (typeof data === 'object' && data.cameras && data.cameras.length) {
            for (let i = 0; i < data.cameras.length; i++) {
                const camera             = data.cameras[i];
                const point              = new THREE.Vector3(camera.translation.x, camera.translation.y, camera.translation.z);
                const color: THREE.Color = new THREE.Color(0x0055ff);
                let hslColor: THREE.HSL  = {h: 0, s: 0, l: 0};
                hslColor                 = color.getHSL(hslColor);
                color.setHSL(hslColor.h, hslColor.s, Math.random() * hslColor.l);

                geometry.vertices.push(point);
                geometry.colors.push(color);
            }
        }

        geometry.computeBoundingSphere();
        if (geometry.boundingSphere.radius === 0) {
            throw "Empty JSON geometry";
        }

        onObjectLoaded(geometry);
    }

    private static drawPLY(data: ArrayBuffer, onObjectLoaded: objectLoadedCallback): void {
        // @ts-ignore (PLYLoader loaded manually)
        const loader   = new THREE.PLYLoader();
        const geometry = loader.parse(data);

        if (geometry.boundingSphere.radius === 0) {
            throw "Empty PLY geometry";
        }

        onObjectLoaded(geometry);
    }

    public drawColorBackground(color: string): void {
        this.scene.background = new THREE.Color(color);
    }

    public drawCubicBackground(posX: string, negX: string, posY: string, negY: string, posZ: string, negZ: string): void {
        const loader  = new THREE.CubeTextureLoader();
        const texture = loader.load([posX, negX, posY, negY, posZ, negZ]);

        const shader                  = THREE.ShaderLib.cube;
        const material                = new THREE.ShaderMaterial({
            fragmentShader: shader.fragmentShader,
            vertexShader:   shader.vertexShader,
            uniforms:       shader.uniforms,
            depthWrite:     false,
            side:           THREE.BackSide,
        });
        material.uniforms.tCube.value = texture;
        const plane                   = new THREE.BoxBufferGeometry(this.controlsOrbit.maxDistance, this.controlsOrbit.maxDistance, this.controlsOrbit.maxDistance);
        this.background               = new THREE.Mesh(plane, material);

        this.scene.add(this.background);
    }

    public drawData(data, center: THREE.Vector3, type: ThreeJSHelper.ModelType, pointSize: number, drawFakeObject: boolean, contentType: string): Promise<THREE.Geometry | THREE.BufferGeometry | THREE.Scene> {
        return new Promise((resolve: (value: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) => void) => {

            this.renderedImage = undefined;

            switch (contentType) {
                case 'image/jpeg':
                    jQuery(this.containerModel).hide();
                    jQuery(this.containerImage).show();

                    this.fixCanvasSize(this.containerImage);
                    $.when(this.getImageFromResponseData(data)).done((image) => {
                        this.drawImage(image);
                    });
                    break;
                default:
                    const onObjectLoaded = (object: THREE.Scene) => {
                        if (typeof object !== 'undefined') {
                            jQuery(this.containerImage).hide();
                            jQuery(this.containerModel).show();
                            this.fixCanvasSize(this.containerModel);

                            this.draw(object, center, type, pointSize, drawFakeObject);
                            resolve(object);
                        }
                    };

                    try {
                        ThreeJSHelper.drawJSON(data, onObjectLoaded);
                    } catch (e) {
                        if (e === "Empty JSON geometry") {
                            try {
                                ThreeJSHelper.drawPLY(data, onObjectLoaded);
                            } catch (e) {
                                if (e === "Empty PLY geometry") {
                                    try {
                                        this.drawGLTF(data, onObjectLoaded);
                                    } catch (e) {
                                        console.error(e);
                                    }
                                }
                            }
                        }
                    }
            }
        });
    }

    public drawImageBackground(image: string): void {
        const loader          = new THREE.TextureLoader();
        const texture         = loader.load(image);
        this.scene.background = texture;
    }

    public drawPanoBackground(image: string): void {
        const geometry = new THREE.SphereBufferGeometry(this.controlsOrbit.maxDistance, 60, 40);
        // invert the geometry on the x-axis so that all of the faces point inward
        geometry.scale(-1, 1, 1);

        const loader    = new THREE.TextureLoader();
        const texture   = loader.load(image);
        const material  = new THREE.MeshBasicMaterial({map: texture});
        this.background = new THREE.Mesh(geometry, material);

        this.scene.add(this.background);
    }

    public getAnimated(): AnimationStatus {
        return this.animated;
    }

    public hideAxesHelper(): void {
        this.scene.remove(this.axesHelper);
    }

    public init(): void {
        // Camera
        this.camera       = new THREE.PerspectiveCamera(35, this.aspectRatio, 1, 9999999);
        this.cameraTarget = new THREE.Vector3(0, 0, 0);

        // Scene
        this.scene            = new THREE.Scene();
        this.scene.background = new THREE.Color(this.backgroundColor);

        // Lights
        this.scene.add(new THREE.HemisphereLight(0x443333, 0x111122));
        this.scene.add(new THREE.AmbientLight(0xffffff, 1));

        // Renderer
        this.renderer = new THREE.WebGLRenderer({antialias: true});
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.gammaInput        = true;
        this.renderer.gammaOutput       = true;
        this.renderer.shadowMap.enabled = true;
        this.containerModel.appendChild(this.renderer.domElement);

        // Controls
        this.controlsOrbit            = new OrbitControls(this.camera, this.renderer.domElement);
        this.controlsOrbit.enableZoom = true;
        this.controlsOrbit.enableKeys = false;

        this.containerModel.addEventListener('mousedown', () => {
            if (this.animated === AnimationStatus.enabled) {
                this.previousAnimated = this.getAnimated();
                this.setAnimated(AnimationStatus.disabled);
            }
        });
        this.containerModel.addEventListener('mouseup', () => {
            if (typeof this.previousAnimated !== 'undefined') {
                this.setAnimated(this.previousAnimated);
                this.previousAnimated = undefined;
            }
        });

        // Events
        window.addEventListener('resize', () => {
            this.fixCanvasSize()
        }, false);

        // Init
        this.fixCanvasSize();
        this.animate();
    };

    public lockCenterCamera(): void {
        this.controlsOrbit.enableZoom = false;
        this.camera.position.set(-0.0000000000001, 0, 0);
        this.hideAxesHelper();
    }

    public onAnimated(handler: animatedHandler): void {
        this.animatedHandler = handler;
    }

    public removeBackground() {
        this.scene.remove(this.background);
    }

    public removeObject(geometry: THREE.Geometry | THREE.BufferGeometry | THREE.Scene) {
        this.scene.remove(<THREE.Object3D>geometry);
    }

    public setAnimated(animated: AnimationStatus): void {
        this.animated = animated;

        if (typeof this.animatedHandler !== 'undefined') {
            this.animatedHandler(this.animated);
        }
    };

    public setSceneSize(size: number): void {
        this.controlsOrbit.maxDistance = size;
    }

    public showAxesHelper(): void {
        this.scene.add(this.axesHelper);
    }

    public unlockCenterCamera(): void {
        this.controlsOrbit.enableZoom = true;
        this.camera.position.set(1, 1, 1);
        this.showAxesHelper();
    }

    private animate(): void {
        requestAnimationFrame(() => {
            this.animate()
        });
        this.render();
    }

    private draw(geometry: THREE.Geometry | THREE.BufferGeometry | THREE.Scene, center: THREE.Vector3, type: ThreeJSHelper.ModelType, pointSize: number, drawFakeObject: boolean): void {
        if (this.renderedObject && !this.allowMultipleObjects) {
            this.scene.remove(this.renderedObject);
            this.renderedObject = undefined;
        }
        if (this.fakeObject) {
            this.scene.remove(this.fakeObject);
            this.fakeObject = undefined;
        }

        let size: THREE.Vector3 = new THREE.Vector3();
        if (geometry instanceof THREE.Scene) {
            new THREE.Geometry();
            geometry.translateX(center.x);
            geometry.translateY(center.y);
            geometry.translateZ(center.z);

            this.drawScene(geometry, size);
        }
        else if (geometry instanceof THREE.Geometry || geometry instanceof THREE.BufferGeometry) {
            this.drawGeometry(geometry, center, size, type, pointSize);
        }

        if (drawFakeObject) {
            const fakeObjectGeometry = new THREE.SphereGeometry(2, 32, 32);
            const fakeObjectMaterial = new THREE.MeshBasicMaterial({color: 0xff8000});
            this.fakeObject          = new THREE.Mesh(fakeObjectGeometry, fakeObjectMaterial);
            this.scene.add(this.fakeObject);
        }

        this.distanceCameraToObject = Math.max(size.x * 1.5, size.y * 2, size.z * 1.5);

        // Posiciones de las luces
        const verticalAxis = new THREE.Vector3(0, 1, 0);
        const posLight0    = new THREE.Vector3(this.distanceCameraToObject, this.distanceCameraToObject, this.distanceCameraToObject);
        const posLight1    = posLight0.clone();
        posLight1.applyAxisAngle(verticalAxis, Math.PI * 2 / 3);
        const posLight2 = posLight0.clone();
        posLight2.applyAxisAngle(verticalAxis, Math.PI * 4 / 3);

        let cameraHeight = size.y / 2;
        if (!this.getAnimated()) {
            cameraHeight = this.distanceCameraToObject / 2;
        }

        const coords = Math.sqrt(Math.pow(this.distanceCameraToObject, 2) / 2);
        this.camera.position.set(coords, cameraHeight, coords);
    }

    private drawGLTF(data: ArrayBuffer, onObjectLoaded: objectLoadedCallback): void {
        try {
            const loader = new GLTFLoader();
            loader.parse(data, undefined, (GLTF: THREE.GLTF) => {
                onObjectLoaded(GLTF.scene)
            });
        } catch (e) {
            throw "Empty GLTF geometry";
        }
    }

    private drawGeometry(geometry: THREE.Geometry | THREE.BufferGeometry, center: THREE.Vector3, size: THREE.Vector3, type, pointSize: number): void {
        geometry.computeVertexNormals();
        geometry.computeBoundingBox();

        geometry.boundingBox.getCenter(center);
        geometry.boundingBox.getSize(size);

        if (type === 'mesh') {
            const meshMaterial = new THREE.MeshStandardMaterial({
                // color:       0x0055ff,
                flatShading:  true,
                vertexColors: THREE.VertexColors
            });
            const mesh         = new THREE.Mesh(geometry, meshMaterial);

            mesh.castShadow    = true;
            mesh.receiveShadow = true;
            mesh.position.x    = -center.x;
            mesh.position.y    = -center.y;
            mesh.position.z    = -center.z;

            this.renderedObject = mesh;
            this.scene.add(mesh);
        }
        else if (type === 'cloud') {
            const cloudMaterial = new THREE.PointsMaterial({
                // color:        0x0055ff,
                size:         pointSize || 0.1,
                vertexColors: THREE.VertexColors
            });
            const cloud         = new THREE.Points(geometry, cloudMaterial);

            cloud.castShadow    = true;
            cloud.receiveShadow = true;
            cloud.position.x    = -center.x;
            cloud.position.y    = -center.y;
            cloud.position.z    = -center.z;

            this.renderedObject = cloud;
            this.scene.add(cloud);
        }
    }

    private drawImage(image: HTMLImageElement): void {
        const containerSize: number         = Math.min(this.containerImage.clientWidth, this.containerImage.clientHeight);
        const canvas: HTMLCanvasElement     = jQuery(this.containerImage).find('canvas')[0] as HTMLCanvasElement;
        const posX: number                  = Math.abs(this.containerImage.clientWidth - containerSize) / 2;
        const posY: number                  = Math.abs(this.containerImage.clientHeight - containerSize) / 2;
        const ctx: CanvasRenderingContext2D = canvas.getContext('2d');

        ctx.fillStyle = this.backgroundColor;
        ctx.rect(0, 0, canvas.width, canvas.height);
        ctx.fill();
        ctx.drawImage(image, 0, 0, image.width, image.height, posX, posY, containerSize, containerSize);

        this.renderedImage = image;
    }

    private drawScene(geometry: THREE.Scene, size: THREE.Vector3): void {
        const box = new THREE.Box3().setFromObject(geometry);
        box.getSize(size);

        this.renderedObject = geometry;
        this.scene.add(geometry);
    }

    private fixCanvasSize(container?: HTMLElement): void {
        // If not specified, get visible container
        if (typeof container === 'undefined') {
            if (jQuery(this.containerModel).is(':visible')) {
                container = this.containerModel;
            }
            else if (jQuery(this.containerImage).is(':visible')) {
                container = this.containerImage;
            }
        }

        // Update canvas size
        const size: Size                = this.getCanvasSize(container);
        const canvas: HTMLCanvasElement = jQuery(container).find('canvas')[0] as HTMLCanvasElement;
        canvas.height                   = size.height;
        canvas.width                    = size.width;

        // Update Three.js viewer
        this.renderer.setSize(size.width, size.height);
        this.camera.aspect = this.aspectRatio;
        this.camera.updateProjectionMatrix();

        // Redraw image
        if (typeof this.renderedImage !== 'undefined') {
            this.drawImage(this.renderedImage);
        }
    }

    private getCanvasSize(container: HTMLElement): Size {
        let width = container.clientWidth;
        width -= Math.round(parseFloat(window.getComputedStyle(container).getPropertyValue('padding-left')));
        width -= Math.round(parseFloat(window.getComputedStyle(container).getPropertyValue('padding-right')));

        const height = width / this.aspectRatio;

        return {width, height};
    }

    private getImageFromResponseData(responseData: string): Promise<HTMLImageElement> {
        return new Promise<HTMLImageElement>((resolve) => {
            const image  = new Image();
            image.onload = () => {
                resolve(image);
            };
            image.src    = URL.createObjectURL(responseData);
        });
    }

    private render(): void {
        // Calculamos la distancia con las coordenadas para permitir el zoom
        const x                     = this.camera.position.x;
        const z                     = this.camera.position.z;
        this.distanceCameraToObject = Math.sqrt((x * x) + (z * z));

        if (this.getAnimated() === AnimationStatus.enabled) {
            this.camera.position.x = x * Math.cos(this.animationSpeed) + z * Math.sin(this.animationSpeed);
            this.camera.position.z = z * Math.cos(this.animationSpeed) - x * Math.sin(this.animationSpeed);
            this.camera.lookAt(this.cameraTarget);
        }
        else if (this.getAnimated() === AnimationStatus.disabled) {
            this.camera.lookAt(this.cameraTarget);
        }

        this.renderer.render(this.scene, this.camera);
    }
}

module ThreeJSHelper {
    export enum ModelType {
        cloud = 'cloud',
        mesh  = 'mesh',
        gltf  = 'gltf'
    }
}

export default ThreeJSHelper;
