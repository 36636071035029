import RequestHelper from "./request-helper";

export default class RecreaManagerCreate {
    private btnNew: JQuery<HTMLElement>;
    private container: JQuery<HTMLElement>;
    private form: JQuery<HTMLElement>;
    private modal: JQuery<HTMLElement>;
    private submitBtn: JQuery<HTMLElement>;
    private titleInput: JQuery<HTMLElement>;

    constructor(elem: JQuery<HTMLElement>) {
        this.container  = elem;
        this.btnNew     = this.container.find('.btn-new-recrea');
        this.modal      = this.container.find('.modal');
        this.form       = this.modal.find('form');
        this.submitBtn  = this.form.find('button[type="submit"]');
        this.titleInput = this.form.find('input[name="recrea[title]"]');

        this.submitBtn.prop('disabled', true);


        this.titleInput.on('change', () => {
            this.titleInput.val(this.titleInput.val().toString().trim());
            this.checkSubmitEnabled();
        });

        this.titleInput.on('keyup', () => {
            this.checkSubmitEnabled();
        });

        this.btnNew.on('click', (e) => {
            e.preventDefault();
            e.stopPropagation();

            this.modal.modal();
        });

        this.form.on('submit', (e) => {
            e.preventDefault();
            e.stopPropagation();

            const data          = this.form.serializeArray();
            const requestHelper = new RequestHelper();

            let parsedData = {};
            for (let item of data) {
                parsedData[item.name] = item.value;
            }

            requestHelper
                .post(
                    this.form.attr('action'),
                    parsedData,
                    'json'
                )
                .then((response: XMLHttpRequest) => {
                    window.location = response.response.editUrl;
                });
        });
    }

    private checkSubmitEnabled() {
        this.submitBtn.prop('disabled', this.titleInput.val().toString().length === 0);
    }
}
