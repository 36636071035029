import 'jstree/dist/themes/default/style.css'
import 'jstree';

interface JSTreeJsonNode {
    a_attr: string
    children: Array<JSTreeJsonNode>
    data: any,
    icon: any,
    id: string,
    li_attr: any,
    state: JSTreeJsonNodeStatus,
    text: string,
}

interface JSTreeJsonNodeStatus {
    disabled: boolean
    loaded: boolean,
    opened: boolean,
    selected: boolean,
}

interface ParsedJsonNode {
    children: Array<ParsedJsonNode>
    id: string,
    itemId: string
}

class TreeEditor {
    private contextMenuItemsCallable: (any) => any;
    private readonly elem: JQuery<HTMLElement>;
    private readonly input: JQuery<HTMLInputElement>;

    constructor(elem: JQuery<HTMLElement>) {
        this.elem  = elem;
        this.input = jQuery('#' + elem.data('target'));

        this.init(elem);
    }

    public setContextMenuitemsCallable(callableFunction: (any) => any): void {
        this.contextMenuItemsCallable = callableFunction;
    }

    private checkParentContent(operation, node, node_parent) {
        if (operation === 'move_node' && node_parent.data.type === 'content') {
            return node_parent.children.length === 0;
        }

        return true;
    }

    private checkValidRoot(operation, node): boolean {
        return !(operation === 'move_node' && typeof node.parent !== 'string');
    }

    private getContextMenuItems(node): any {
        if (typeof this.contextMenuItemsCallable !== 'undefined') {
            return this.contextMenuItemsCallable(node);
        }

        return {};
    }

    private init(elem: JQuery<HTMLElement>) {
        elem
            .jstree({
                core:        {
                    dblclick_toggle: false,
                    check_callback:  (operation, node, node_parent, node_position, more) => {
                        return this.checkValidRoot(operation, node_parent)
                            && this.checkParentContent(operation, node, node_parent);
                    },
                    multiple:        false
                },
                contextmenu: {
                    items: (node) => this.getContextMenuItems(node)
                },
                plugins:     ["dnd", "contextmenu"]
            })
            .on('move_node.jstree', (event: JQuery.Event, node) => {
                elem.jstree('open_node', node.parent);
            })
            .on('create_node.jstree delete_node.jstree move_node.jstree load_node.jstree', () => {
                this.updateInputValue();
            })
            .on('loaded.jstree', () => {
                elem.jstree('open_all');
            });
        elem.jstree('open_all');

        elem.find('li a:not(.jstree-anchor)').on('click', (e: JQuery.TriggeredEvent) => {
            const target: HTMLAnchorElement = e.target;
            window.open(target.href, target.target)
        });

        this.updateInputValue();
    }

    private updateInputValue() {
        const json = this.elem.jstree('get_json') as Array<JSTreeJsonNode>;

        this.input.val(JSON.stringify(json));
    }
}

export default TreeEditor;

declare global {
    interface JQuery {
        treeEditor()
    }
}

(function ($) {
    $.fn.treeEditor = function () {
        return this.each((index: number, elem: HTMLElement) => {
            let treeEditor = jQuery(elem).data('treeEditor');
            if (typeof treeEditor === 'undefined') {
                treeEditor = new TreeEditor(jQuery(elem));
                jQuery(elem).data('treeEditor', treeEditor);
            }

            return treeEditor;
        });
    }
})(jQuery);
