namespace Spinner {
    type Position = "center" | "left";
    type Size = "normal" | "small";

    interface SpinnerOptions {
        position?: Position,
        size?: Size
    }

    export class Spinner {
        private static readonly defaultOptions: SpinnerOptions = {
            position: "center",
            size:     "normal"
        };

        public static get(options?: SpinnerOptions): JQuery<HTMLElement> {
            if (typeof options === "undefined") {
                options = Spinner.defaultOptions;
            }

            const spinner = jQuery('<div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>');

            switch (options.position) {
                case "center":
                    spinner.addClass('d-block mx-auto');
                    break;
                case "left":
                    spinner.addClass('mr-1');
                    break;
            }

            switch (options.size) {
                case "small":
                    spinner.addClass('spinner-border-sm');
                    break;
            }

            return spinner;
        }

        public static remove(parent: JQuery<HTMLElement>) {
            parent.children('.spinner-border').remove();
        }
    }
}

export default Spinner.Spinner;
