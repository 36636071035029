import RequestHelper from "./request-helper";

class RecreaAPI {
    private readonly baseApiURL: string           = 'https://recrea3d.gvam.es/api/v1';
    private readonly requestHelper: RequestHelper = new RequestHelper();

    public createProject(projectId: string): Promise<object> {
        return new Promise((resolve) => {
            const data = {
                id:    projectId,
                title: projectId
            };

            projectId = undefined;

            this.requestHelper.post(this.getAPIURL(projectId), data, 'json')
                .then((event) => {
                    resolve(event.response);
                });
        });
    }

    public getAPIURL(projectId: string, endpoint?: RecreaAPI.Endpoints): string {
        let url = this.baseApiURL + '/projects';
        if (projectId) {
            url += '/' + projectId;
        }

        if (typeof endpoint === 'string' && endpoint.length) {
            url += '/' + endpoint;
        }

        return url;
    }

    public getBaseApiURL(): string {
        return this.baseApiURL;
    }

    public getCameras(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.cameras, RecreaAPI.EndpointResponseType.cameras, maxAttempts);
    };

    public getCloud(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.cloud, RecreaAPI.EndpointResponseType.cloud, maxAttempts);
    };

    public getMesh(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.mesh, RecreaAPI.EndpointResponseType.mesh, maxAttempts);
    };

    public getModel(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.model, RecreaAPI.EndpointResponseType.model, maxAttempts);
    };

    public getNormalmap(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.normalmap, RecreaAPI.EndpointResponseType.normalmap, maxAttempts);
    };

    public getTexture(projectId: string, maxAttempts?: number): Promise<XMLHttpRequest> {
        return this.getObject(projectId, RecreaAPI.Endpoints.texture, RecreaAPI.EndpointResponseType.texture, maxAttempts);
    };

    private getObject(projectId: string, endpoint: RecreaAPI.Endpoints, responseType: XMLHttpRequestResponseType, maxAttempts?: number): Promise<XMLHttpRequest> {
        return new Promise(resolve => {
            maxAttempts = (typeof maxAttempts !== "undefined") ? maxAttempts : 5;

            const url   = this.getAPIURL(projectId, endpoint);
            let attempt = 0;

            const loadData = (): void => {
                attempt++;

                this.requestHelper.get(url, responseType).then((event) => {
                    if ((event.status !== 200 /*|| originalUrl !== event.target.responseURL*/) && attempt <= maxAttempts) {
                        setTimeout(function () {
                            loadData();
                        }, 500);
                    }
                    else {
                        resolve(event);
                    }
                });
            };

            loadData();
        });
    }
}

module RecreaAPI {
    export enum Endpoints {
        model      = 'model',
        normalmap  = 'normalmap',
        texture    = 'texture',
        mesh       = 'mesh',
        cloud      = 'cloud',
        cameras    = 'cameras',
        photos     = 'photos',
        thumbnails = 'thumbnails'
    }

    export enum EndpointResponseType {
        model     = 'arraybuffer',
        normalmap = 'blob',
        texture   = 'blob',
        mesh      = 'arraybuffer',
        cloud     = 'arraybuffer',
        cameras   = 'json'
    }
}

export default RecreaAPI;
