class LocutionManager {
    constructor(elem) {
        const $elem         = $(elem);
        const $btnModal     = $elem.find('.generate_locution');
        const $rowLocution  = $btnModal.parents('.row.locution');
        const $modal        = $rowLocution.find('.modal-generate');
        const $btnGenerate  = $modal.find('.btn-generate');
        const $btnCancel    = $modal.find('.btn-cancel');
        const $btnClose     = $modal.find('.btn-close');
        const $locutionText = $('#' + $btnModal.data('locution_text_id'));

        $modal
            .on('shown.bs.modal', function () {
                $btnGenerate.on('click', (e: JQuery.TriggeredEvent) => {
                    e.preventDefault();

                    const url   = $modal.find('input[name="api_url"]').val().toString();
                    const voice = $modal.find('select[name="locution_voice"]').val().toString();
                    const text  = $locutionText.val();

                    lockModalButtons();

                    jQuery.post(
                        url,
                        {
                            voice: voice,
                            text:  text
                        },
                        function (e) {
                            $rowLocution.find('.advanced-file-manager')
                                .advancedUploader('setValue', e.file)
                                .advancedUploader('hidePreview');
                            $modal.modal('hide');

                            unlockModalButtons();
                        },
                        'json'
                    );
                });
            })
            .on('hidden.bs.modal', function () {
                $btnGenerate.off('click');
            });

        $btnModal.on('click', (e: JQuery.TriggeredEvent) => {
            e.preventDefault();

            if (!$btnModal.hasClass('disabled')) {
                $modal.modal({
                    backdrop: 'static'
                });
            }
        });

        $locutionText.on('keyup keypress input change', function () {
            if ($locutionText.val().toString().length > 0) {
                enableModalButton();
            }
            else {
                disableModalButton();
            }
        });

        $locutionText.trigger('change');

        function lockModalButtons() {
            $btnGenerate.find('.spinner-border').removeClass('d-none');
            $btnGenerate.attr('disabled', 'disabled');
            $btnCancel.attr('disabled', 'disabled');
            $btnClose.attr('disabled', 'disabled');
        }

        function unlockModalButtons() {
            $btnGenerate.find('.spinner-border').addClass('d-none');
            $btnGenerate.removeAttr('disabled');
            $btnCancel.removeAttr('disabled');
            $btnClose.removeAttr('disabled');
        }

        function disableModalButton() {
            $btnModal.addClass('disabled');
            $btnModal.attr('title', $btnModal.data('title'));
            $btnModal.attr('data-toggle', 'tooltip');
            $btnModal.tooltip('enable');
        }

        function enableModalButton() {
            $btnModal.removeClass('disabled');
            $btnModal.attr('title', null);
            $btnModal.attr('data-toggle', null);
            $btnModal.tooltip('disable');
        }
    }
}

interface JQuery {
    locutionManager();
}

(function ($) {
    $.fn.locutionManager = function () {
        return this.each((index: number, elem: HTMLElement) => {
            new LocutionManager(jQuery(elem));
        });
    }
})(jQuery);
